import React, {useEffect, useState} from 'react';
import './Paymenthis.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import Paymentdetail from '../Paymentdetail/Paymentdetail';
import axios from 'axios';
import Loading from '../Loading/Loading';
import nopayment from '../../../Asserts/Images/nopayment.png'
import { useSessionErrorHandler } from '../Managesession';
import BranchLoading from '../branchLoading/BranchLoading';


function Paymenthis() {
  const handleSessionError = useSessionErrorHandler();
  const navigate = useNavigate();
const {user,loading,setLoading,token} = useContext(UserContext);
const [payments,setpayment] = useState([])
const getuserPayements = async()=>{

  setLoading(true)
  axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/user/${user._id}`,{
    headers:{
      authorization:`Bearer ${token}`
    }
  }).then((response)=>{
    console.log(response.data.payments)
    setpayment(response.data.payments)
    setLoading(false)

  }).catch((err)=>{
    handleSessionError(err)

    setLoading(false)
    console.log(err)
  })
}
const handleAllButtonClick = async()=>{
  setLoading(true)
  await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/all`,{
    headers:{
      authorization:`Bearer ${token}`
    }
  }).then((res)=>{
    console.log(res)
    setpayment(res.data.transcition)
    setLoading(false)
  }).catch((err)=>{
    handleSessionError(err)
    setLoading(false)
    console.log(err)
  })
}
const handleTodayButtonClick = async()=>{
  try{
    setLoading(true)
     await axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/today/payments`,{
      headers:{
        authorization:`Bearer ${token}`
      }

     }).then((res)=>{
        console.log(res)
        setpayment(res.data.payments)
        setLoading(false)
      })

  }catch(err){
    handleSessionError(err)

    console.log(err)
    setLoading(false)
  }
}
const handleTranscitionData = async()=>{
  if(user.role==="admin"){
    return handleAllButtonClick()
  }
  else if(user.role === 'user'){
     getuserPayements()
  }
}
useEffect(()=>{
handleTranscitionData()

},[])
  const [activeButton, setActiveButton] = useState('all');
const [paymentdet ,setPaymentdet] = useState("payment");
const [paymentid ,setpaymentid] = useState("")
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };



  const goback = (id) => {
    navigate(`/paymentdetail/${id}`)
    // setPaymentdet("paymentdetail");
  };

  return (
    
    <>

      <div className="paymenthismain">
        <div className="paymenthisnav">
          <Navbar />
        </div>

        {
          paymentdet === "payment" &&
           <>



        <div className="paymenthiscontainer">
          <div className="paymenthistitle">
            مدفوعاتي
          </div>

        {
          user.role === "admin" && <>
            <div className="paymentnavigationmain">
        <div
          className={activeButton === 'all' ? 'payactive' : ''}
          onClick={() => handleAllButtonClick('all')}
        >
          الكل
        </div>
        <div
          className={activeButton === 'today' ? 'payactive' : ''}
          onClick={() => handleTodayButtonClick('today')}
        >
          اليوم
        </div>
      </div>
          </>
        }

{
  
  loading ? <BranchLoading/> : <>{
    payments&& payments.length>0? payments.map((payment) => {
      // Convert the created_at string to a Date object
      const paymentDate = new Date(payment.created_at);
    
      // Format the time as hh:mm am/pm
      const timeOptions = { hour: '2-digit', minute: '2-digit' };
      const formattedTime = paymentDate.toLocaleTimeString('en-US', timeOptions);
    
      // Format the date as dd/mm/yyyy
      const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const formattedDate = paymentDate.toLocaleDateString('en-GB', dateOptions);
    
      return (
        <div
          key={payment.id}
          className="paymenttopmain"
          onClick={()=> goback(payment.transcition_id)}
          style={{ cursor: 'pointer' }}
        >
          <div className="paymenttopleft">
            {payment.transcition_amount}
          </div>
          <div className="payenttopright">
            <div>{payment.transcition_description}</div>
            <div>
              <span>{formattedTime}</span> {formattedDate}
            </div>
            <div><span>Status: </span>{payment.transcition_status}</div>
          </div>
        </div>
      );
    }):
    <>
    <div className="nopaymentmain">
    <img src={nopayment} alt="" />
    لا يوجد أي مدفوعات
    </div>
    
    </>
  }</>

}
        </div>

        </>
        }

{
          paymentdet === "paymentdetail" &&
           <>
{/* <Paymentdetail  paymentid ={paymentid}/> */}
           </>
}
        <div className="paymenthisfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Paymenthis;