import React from 'react';
import { Navigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

const RestrictionsRules = ({ children }) => {
  const { user,verification } = useContext(UserContext);

  // Check if the user is logged in
  if (!user) {
    // If not logged in, redirect to the login page
    alert('Please Login First')
  }

  // Get the user's role
//   const userRole = user.role;

  // Check if the user's role is in the allowedRoles list
  return children
};

export default RestrictionsRules;
