import React from 'react'
import './interviewdetails.css'


function Meetingdetails({Meeting}) {
  return (
    <>

    <div className="meetingdetaileng">


<div className="meettingdeteng">
  <div>Zoom Meeting</div>
  <a href={Meeting.Meeting_url}>Zoom Meeting Link</a>
  <div>Meeting ID:  {Meeting.Meeting_Zoom_ID}</div>
  <div>Passcode: {Meeting.Meeting_Passcode}</div>
</div>

{/* <div className="onetapmobile">
  <div>One tap mobile</div>
  <div>+15074734847,,84635236703#,,,,*798917# US</div>
  <div>+15642172000,,84635236703#,,,,*798917# US</div>
</div> */}


{/* <div className="dailbylocation">
Dial by your location
</div> */}


{/* <div className="meetingauths">
  <div>Meeting ID:  823 6703</div>
  <div>Passcode: 7987</div>
</div> */}

{/* 
<div className="findyoulocalnum">
Find your local number: https://uweb.zoom.u
</div> */}

</div>

    </>
  )
}

export default Meetingdetails;