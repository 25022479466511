import React, { useState, useContext } from 'react';
import './interviewdetails.css';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import { useSessionErrorHandler } from '../Managesession';
import { useNavigate, useParams } from "react-router-dom";
const Payuser = ({ Meeting_category,  interviewID }) => {
  const handleSessionError = useSessionErrorHandler();
  const { user,token } = useContext(UserContext);
  const [fadeOut, setFadeOut] = useState(false);

  // Function to initiate the payment process
  const gotopay = async () => {
    try {
      // Set up payment details
      const paymentData = {
        title: "Meeting Payment",
        service_details: "Payment for meeting",
        order_description: "Payment for attending the meeting",
        order_amount: 1, // Adjust as necessary
        payer_email: user.email,
        payer_id: user._id,
        zoomReqID: interviewID,
        payer_first_name: user.first_name,
        payer_last_name: user.last_name,
        payer_phone: user.phone_number
      };

      // Create an axios instance
      const axiosInstance = axios.create({
        withCredentials: true,
        headers: { "Content-Type": "multipart/form-data" ,
        authorization:`Bearer ${token}`}
      });

      // Send request to EDFAPay
      const formData = new FormData();
      Object.keys(paymentData).forEach(key => formData.append(key, paymentData[key]));

      setFadeOut(true); // Trigger fade-out effect

      const response = await axiosInstance.post(`${process.env.REACT_APP_SERVER_URL}/edfapay/getzoomMeetingTransDetails`, formData);

      if (response.status === 200) {
        // Wait for the fade-out effect to complete before redirecting
        setTimeout(() => {
          window.location.href = response.data.redirect_url;
        }, 500); // Delay to match CSS transition
      } else {
        console.error("Payment initiation failed");
        alert("An error occurred while initiating the payment. Please try again.");
        setFadeOut(false); // Reset fade-out state on error
      }
    } catch (error) {
      console.error("Error during payment initiation:", error);
      handleSessionError(error);
      alert("An error occurred while processing your payment. Please try again.");
      setFadeOut(false); // Reset fade-out state on error
    }
  };

  return (
    <>
      {user && user.role === 'user' && Meeting_category === 'لقاءات غير مدفوعة' && (
        <div className={`payuser-container ${fadeOut ? 'fade-out' : ''}`}>
          <div className="boublebordertapprove">
            تمت الموافقة بالرجاء الدفع لتأكيد الحضور
          </div>

          <div className="buttondivpaybut">
            <button onClick={gotopay} aria-label="Pay for meeting">ادفع</button>
          </div>

          <div className="priceofthemeeting">
            تبلغ تكلفة اللقاء 2000 ريال سعودي.
          </div>
        </div>
      )}
    </>
  );
};

export default Payuser;
