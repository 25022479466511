import React, { useContext, useState } from 'react'
import samplePic from './../../../../../../Asserts/Images/test.png'
import { ManagersContext } from '../../../ManagersManagementContext/ManagersContext'
import { useNavigate } from 'react-router-dom'

function ManagerLatestModifications(activityitem) {
  console.log("activity modification",activityitem)
  const navigate = useNavigate()
  const [activityLatestMods, setActivityLatestMods] = useState(activityitem?.activityVideos)
  return (
    <div className='managerdetails-modifications-container'>
      {
        activityLatestMods?.length>0? activityLatestMods.map((item)=>{
          return(
            <div onClick={()=>navigate(`/videos/${item._id}`)} className='managerdetails-modification-box'>
        <div className='managerdetails-modifications-box-upper'>
          <div className='managerdetails-modifications-upper-tareekh'>{item.modificationDate} : تاريخ التعديل</div>
          {/* <div className='managerdetails-modifications-upper-changes'>{item.mods} : تم تعديل</div> */}
        </div>
        <div className='managerdetails-modifications-box-lower'>
          <div className='managerdetails-video-box'>
        <div className='managerdetails-video-box-left'>
          <img src={samplePic} alt="Doctor" />
        </div>
        <div className='managerdetails-video-box-right'>
        <div className="managerdetails-right-titletime">
              <div className="managerdetails-right-heading">{item.title}</div>
              {/* <div className="managerdetails-right-time">{item.duration}</div> */}
            </div>
          <div className='managerdetails-right-description'>{item.description}</div>
        </div>
      </div></div>
      </div>
          )
        })
      :"No Activities yet..."}

    </div>
  )
}

export default ManagerLatestModifications
