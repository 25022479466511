import React, { useEffect, useState, useContext } from 'react';
import './Paymentdetail.css';
import InvoiceTemplate from './InvoiceTemplate';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import Loading from '../Loading/Loading';
import '../payment/Payment.css';
import { useSessionErrorHandler } from '../Managesession';

function Paymentdetail({ paymentid }) {
  const handleSessionError = useSessionErrorHandler();
  const { user, loading, setLoading, token } = useContext(UserContext);
  const navigate = useNavigate();
  const [paymentdata, setpaymentdata] = useState({});
  const { id } = useParams();

  const goback = () => {
    navigate('/');
  };

  const downloadInvoice = async () => {
    try {
      setLoading(true);
      // Create a URL for the blob and initiate a download
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const gettranscitionbyId = async () => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER_URL}/payments/${id}`, {
      headers: {
        authorization: `Bearer ${token}`
      }
    }).then((response) => {
      console.log('paymentdata', response.data.payment);
      setpaymentdata(response.data.payment);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      handleSessionError(err);
      console.log(err);
    });
  };

  useEffect(() => {
    console.log(id);
    gettranscitionbyId();
  }, []);

  return (
    loading ? <Loading /> :
      <>
        <Navbar />
        <div className='payemntdetailsmaintop'>
          <div className="paymentleftsideandheading">
            <div className='leftarrow'>
              <Icon onClick={goback} className='leftarrowicon' icon="mdi:keyboard-arrow-left" />
            </div>
            <div className='paymenttextdiv'>
              <h1> invoice{paymentdata.transcition_id}</h1>
            </div>
          </div>

          <div className="detailcontentmaindiv">
            <div>
              <h2>معلومات البائع</h2>
            </div>

            <div className='pandspandivpaymentdetail'>
              <p>الاسم : <span>{paymentdata.seller_information}</span> </p>
              <h3>معلومات المشتري</h3>
              <p>الاسم : <span>{paymentdata.transcition_by?.first_name + paymentdata.transcition_by?.last_name}</span> </p>
              <p>رقم الهاتف : <span>{paymentdata.transcition_by?.phone_number}</span> </p>
              <h4>تفاصيل الخدمة</h4>
            </div>
            <h2>Status: {paymentdata.transcition_status} </h2>

            <div className='showpaymentdiv'>
              <h5>{paymentdata.services_details}</h5>
              <h5>{paymentdata.transcition_amount} ريال</h5>
            </div>

            <h2> اجمالي المبلغ</h2>
            <h6>{paymentdata.transcition_amount} ريال</h6>

            {Object.keys(paymentdata).length > 0 && (
              <div className='btnpaymentdetaildiv'>
                <PDFDownloadLink
                  document={<InvoiceTemplate paymentData={paymentdata} />}
                  fileName={`Invoice_${paymentdata.transcition_id}.pdf`}
                >
                  {({ loading }) => (
                    <button className='download-btn'>
                      {loading ? 'Loading document...' : 'تحميل الفاتورة'}
                    </button>
                  )}
                </PDFDownloadLink>
              </div>
            )}
          </div>
        </div>
      </>
  );
}

export default Paymentdetail;