import React, { useState, useRef, useContext, useEffect } from 'react';
import './Uploadbanner.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import Loading from '../../User/Loading/Loading';
import { useSessionErrorHandler } from '../../User/Managesession';

function Updatebanner() {

  const handleSessionError = useSessionErrorHandler();

  const { id } = useParams(); // Get the ID from the URL
  const navigate = useNavigate();
  const { user, setLoading, loading, setSuccessAction ,token} = useContext(UserContext);
  const [activeButton, setActiveButton] = useState('إعلان متحرك');
  const [isPinned, setIsPinned] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    link: '',
    description: '',
    image: null,
  });

  const [formSubmitted, setFormSubmitted] = useState(false);
  const fileInputRef = useRef(null);
  const [existingImageUrl, setExistingImageUrl] = useState('');


  // Fetch existing data on component mount
  useEffect(() => {
    const fetchBannerData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/banner/read/${id}`,{headers : {authorization : `Bearer ${token}`}});
        const bannerData = response.data.response;
        console.log(bannerData);
        setFormData({
          title: bannerData.title,
          link: bannerData.link,
          description: bannerData.description,
          image: null, // Assuming the image URL is returned
        });
        setExistingImageUrl(bannerData.bannerPic);
        setIsPinned(bannerData.isPinned);
      } catch (error) {
        handleSessionError(error);
        console.error('Error fetching banner data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, [id, setLoading]);

  const goback = () => {
    navigate(-1);
  };

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        image: file,
      }));
      setExistingImageUrl(''); // Clear the existing image if a new one is uploaded
    }
  };

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    return (
      formData.title &&
      formData.link &&
      formData.description &&
      formData.image
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (activeButton === 'إعلان مثبت') {
      setIsPinned(true);
    } else {
      setIsPinned(false);
    }

    if (validateForm()) {
      const formDataToSend = new FormData();
      formDataToSend.append('title', formData.title);
      formDataToSend.append('link', formData.link);
      formDataToSend.append('description', formData.description);
      if (typeof formData.image === 'object') {
        formDataToSend.append('bannerPic', formData.image);
      }
      formDataToSend.append('isPinned', isPinned);
      console.log(formDataToSend);

      setLoading(true);
      try {
        
        await axios.put(`${process.env.REACT_APP_SERVER_URL}/banner/update/${id}`, formDataToSend,{headers : {authorization : `Bearer ${token}`}});
        navigate('/success'); // Navigate to the success page
      } catch (error) {
        console.error('Error updating banner:', error);
        handleSessionError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    loading ? <Loading /> :
    <>
      <div className="uploadbannermaindiv">
        <div className="uplaodbannernavbar">
          <Navbar />
        </div>

        <div className="bannercontaierdiv">
          <div className="toptitlediv">
            <div className="backicondiv" onClick={goback}>
              <Icon icon="ic:round-arrow-back-ios" width="1.2em" height="1.2em" />
            </div>
            <div className="titledivbaner">تعديل الإعلان</div>
          </div>

          <div className="buttonsdiv">
            <button
              className={activeButton === 'إعلان متحرك' ? 'active-button' : ''}
              onClick={() => handleButtonClick('إعلان متحرك')}
            >
              إعلان متحرك
            </button>
            <button
              className={activeButton === 'إعلان مثبت' ? 'active-button' : ''}
              onClick={() => handleButtonClick('إعلان مثبت')}
            >
              إعلان مثبت
            </button>
          </div>

          <div className="uploadimagediv">

          {formData.image ? (
                <img
                  src={URL.createObjectURL(formData.image)}
                  alt="Preview"
                  className="image-preview"
                />
              ) : (
                existingImageUrl && (
                  <img
                  src={`${existingImageUrl}`}
                    alt="Existing Preview"
                    className="image-preview"
                  />
                )
              )}

            <input
              type="file"
              ref={fileInputRef}
              onChange={handleImageUpload}
              style={{ display: 'none' }}
            />
            <button
              onClick={handleCustomButtonClick}
              style={{backgroundColor: formSubmitted && !formData.image && 'red' }}
            >
              ارفع صورة الغلاف
            </button>
          </div>

          <div className="formdivmain">
            <label htmlFor="title">عنوان الإعلان</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleInputChange}
              className={formSubmitted && !formData.title ? 'input-error' : ''}
            />

            <label htmlFor="link">رابط الإعلان</label>
            <input
              type="text"
              name="link"
              value={formData.link}
              onChange={handleInputChange}
              placeholder="بالرجاء ادخال رابط الصفحة الذي تريد ربطه بالإعلان "
              className={formSubmitted && !formData.link ? 'input-error' : ''}
            />

            <label htmlFor="description">الوصف</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              className={formSubmitted && !formData.description ? 'input-error' : ''}
            />
          </div>

          <div className="submitmaindiv">
            <button onClick={handleSubmit}>تم</button>
          </div>
        </div>

        <div className="bannerfooterdiv">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Updatebanner;
