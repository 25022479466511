import React, { useEffect, useState } from "react";
import Navbar from "../../User/Navbar/Navbar";
import Footer from "../../User/Footer/Footer";
import "./Videouploading.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import VideosinVidUp from "./VideosinVidUp";

function Videouploading() {

  const [changetab, setChangetab] = useState("عام");
  const [deletepopup, setDeletepopup] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const { departmentTitle } = location.state || {};

  const goback = () => {
    navigate(-1);
  };

  const performdeletaction = () => {
    // delete the course from the database or any other required actions
    setDeletepopup(false);
  };

  const section = "الفيديوهات";

  const gotouploadvideos = () => {
    navigate(`/uploadnewvideo/${"الفيديوهات"}/${departmentTitle}`);
  };

  const gotosubcatageries = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="videouploadingmain">
        {deletepopup && (
          <>
            <div className="deletemaindiv">
              <div className="deletecontainer">
              <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>

                <textarea
                  name=""
                  id=""
                  placeholder="مختلف عن موضوع القسم"
                ></textarea>
                <button onClick={performdeletaction}>
                  <Icon
                    icon="ic:baseline-delete"
                    width="1.2em"
                    height="1.2em"
                  />
                  حذف{" "}
                </button>
              </div>
            </div>
          </>
        )}

        <div className="videouploadingnavbar">
          <Navbar />
        </div>

        <div className="videouplaodingcontainer">
          <div className="videotitlediv">
            <div className="backicondivabso" onClick={goback}>
              <Icon
                icon="ion:chevron-back-outline"
                width="1.2em"
                height="1.2em"
              />
            </div>
            {/* تجريبي */}
            {departmentTitle && departmentTitle}
          </div>

          <div className="navigationback">
            <span onClick={gotosubcatageries}>الأقسام</span>
            <span>{"<"}</span>
            <span onClick={gotosubcatageries}>الفيديوهات</span>
            <span>{"<"}</span>
            <span>{departmentTitle && departmentTitle}</span>
          </div>

          <div className="navigationbuttonmainbook">
            <div
              onClick={() => setChangetab("مخفي")}
              className={changetab === "مخفي" && "navigationactive"}
            >
              مخفي
            </div>
            <div
              onClick={() => setChangetab("عام")}
              className={changetab === "عام" && "navigationactive"}
            >
              عام
            </div>
          </div>

          <div
            className="uploadedvideosmaindiv"
            style={{
              background: changetab === "عام" ? "#B571AC" : "transparent",
            }}
          >
            {changetab === "عام" && (
              <>
                <div className="uploadingvideobutton">
                  <div
                    className="buttondivbackground"
                    onClick={gotouploadvideos}
                  >
                    <Icon icon="ic:round-plus" width="1.2em" height="1.2em" />
                  </div>
                  <div className="buttondivtitle">إضافة فيديو</div>
                </div>
              </>
            )}
            <VideosinVidUp
              changetab={changetab}
              activeButton={section}
              deptTitle={departmentTitle}
              setChangetab={setChangetab}
            />
          </div>
        </div>

        <div className="videouploadingfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Videouploading;
