import React, { useContext, useEffect, useState } from 'react'
import sampleProfile from './../../../../Asserts/Images/DummyProfile.png'
import active from './../../../../Asserts/Images/Active.png'
import notActive from './../../../../Asserts/Images/notActive.png'
import './usersidebar.css'
import axios from 'axios'
import { UserContext } from '../../../../context/UserContext'
import { loadIcon } from '@iconify/react'
import Loading from '../../Loading/Loading'
import { useSessionErrorHandler } from '../../Managesession'

function UsersSidebar() {
const [datum,setDatum] = useState([])
    const handleSessionError = useSessionErrorHandler()
const {token,user} = useContext(UserContext)
const [sideBarLaoding,setSideBarLoading] = useState(false)
const getAllActiveAdmins = async()=>{
    try{
        setSideBarLoading(true)
        await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/getAllAdmins`,{
            headers:{
                authorization:`Bearer ${token}`

            }
        }).then((response)=>{
            console.log('active admins',response)
            setDatum(response.data.response)
            setSideBarLoading(false)
        }).catch((err)=>{
            setSideBarLoading(false)
          handleSessionError(err)
        })


    }catch(err){
        setSideBarLoading(false)
        console.log(err)
    }
}


    useEffect(() => {
        if (user?.role === 'super-admin') {

                getAllActiveAdmins();


        }
    }, []);

  return (

    sideBarLaoding?'Loading...':
    <div className='statusSideBar'>
      <div><h3 className='heading-in-usersidebar'>المديرون</h3></div>
      <div className='statusSideBar-container'>
        {datum&&datum.length>0?datum.map((item,index)=>{
            return(
                <div className='status-manager-box'>
                    {
                        index !== 0 ?  <div className='status-manager-box-line'></div> : <></>
                    }
                    <div className='status-box-profile'>
                        <div className='status-box-profile-status'>
                            {item.active === true ?
                            <img src={active} alt='Active' /> :
                            <img src={notActive} alt='NotActive' />
                            }
                        </div>
                        <div className='status-box-profile-profile'>
                            <div>{item?.userId?.first_name}</div>
                            <div>
                                <img src={item?.userId?.photo} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className='status-box-data'>
                        <div>{item.totalModifications} : التعديلات اليوم</div>
                        <div>{item.firstLoginTime} : اول ظهور</div>
                    </div>
                </div>
            )
        })
        :"No admins are active today"}
      </div>
    </div>
  )
}

export default UsersSidebar