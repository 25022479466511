import React, { useEffect, useState } from 'react'
import './Userprofile.css'
import { Icon } from '@iconify/react/dist/iconify.js';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import userimg from '../../../Asserts/Images/uesr.svg'
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { useSessionErrorHandler } from '../Managesession';
import axios from 'axios';
function Userprofile() {

    const handleSessionError = useSessionErrorHandler();
const [ userdata, setUserdata] = useState({});
    const {user,token} = useContext(UserContext)
    // const user = JSON.parse(localStorage.getItem('user'))
    console.log(user._id)
    console.log(token)
    const profiledate = user && user.Date_Of_Birth ? user.Date_Of_Birth : "28/8/2024";

    const date = new Date(profiledate);
    const formattedDate = date.toLocaleDateString('en-GB');

    // const user = {
    //     first_name : "khan",
    //     last_name : "jai",
    //     photo : "user1.jpg",
    //     phone_number : "0123456789",
    //     email : "khan@example.com",

    // }
    const getuser = async()=>{
        try{
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/user/userDetails/${user._id}`,{
                headers:{
                    authorization:`Bearer ${token}`
                }
            }).then((response)=>{
                console.log("user data" , response);
                setUserdata(response.data.data)
            }
            ).catch((error)=>{
                console.log(error)
                handleSessionError(error)
                console.log(error);

            })
        }
        catch(err){
            console.log(err)
            handleSessionError(err)
        }
    }
   useEffect(()=>{

    getuser()
   },[])
    // const formattedDate = "28/8/2024";

    return (
        <>
            <div className="profiledatamain">

                <div className="profiledatanav">
                    <Navbar />
                </div>

                <div className="profiledatabackicon">
                    <Icon icon="mdi:keyboard-arrow-left" width="1.2em" height="1.2em" />
                </div>

                <div className="profiledatacontainer">

                    <div className="imagedivup">
                        {userdata.photo ? (
                            <img
                                src={`${userdata.photo}`}
                                alt="user"
                            />
                        ) :
                            (
                                <img src={userimg} alt="" />
                            )
                        }
                    </div>
                    <div className="usernameprofile">
                        {userdata.first_name} {userdata.last_name}
                    </div>
                    <div className="usernamddataetcdivs">
                        <div>رقم الجوال</div>
                        <div>{userdata.phone_number}</div>
                    </div>
                    <div className="usernamddataetcdivs">
                        <div>البريد الالكتروني </div>
                        <div>{userdata.email}</div>
                    </div>
                    <div className="usernamddataetcdivs">
                        <div>تاريخ الميلاد </div>
                        <div>{formattedDate}</div>
                    </div>
                    <div className="usernamddataetcdivs">
                        <div>المرحلة الدراسية </div>
                        <div> الثانوي</div>
                    </div>
                    <div className="usernamddataetcdivs">
                        <div>السنة الدراسية </div>
                        <div>الأول الثانوي</div>
                    </div>

                </div>


                <div className="profiledatafooter">
                    <Footer />
                </div>



            </div>
        </>
    )
}

export default Userprofile;


