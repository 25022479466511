import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js';
import Navbar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
function Error() {
  const {error} = useContext(UserContext);
  const navigate  = useNavigate()
  setTimeout(() => {
    navigate(-1);
  }, 1000);
  return (
    <>

    <div className="suceessmessmain">
<div className="sucessnavbar">
<SimpleNavbar/>
</div>

<div className="sucesscontainerfsdf">
    <div className="circleredick">
    <Icon icon="maki:cross" width="1.2em" height="1.2em" />
    </div>
    <div className="errormessageis">
    {/* حدث خطأ */}
    {error}
    </div>
</div>

{/* <div className="sucessmessagefooter">
    <Footer/>
</div> */}

  </div>
    </>
  )
}

export default Error;