import React, { useEffect,useState } from 'react'
import './Bloodimageanlysis.css'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import images from '../../../Asserts/Images/test.png'
import { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import { Icon } from "@iconify/react";
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../Loading/Loading'
import Chatbox from './Chatbox'
import { useSessionErrorHandler } from '../Managesession'

export default function Bloodimageanlysis({goback,setAnadetails}) {
    const handleSessionError = useSessionErrorHandler();
    const {isverify,loading,setLoading,token} = useContext(UserContext);
    const [RequestData,setRequestData] = useState({})
    const {requestId} = useParams()

    const downloadReplayedDocuments = async()=>{
        if (RequestData.replayed_documents && RequestData.replayed_documents.length > 0) {
            for (const file of RequestData.replayed_documents) {
              const response = await axios.get(file, {
                responseType: 'blob' // Ensure the response type is a Blob
              });
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file); // Set the file name for the download
              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
              window.URL.revokeObjectURL(url); // Clean up the URL object
            }
          }
    }

const navigate = useNavigate();
const getRequestById = async(id)=>{
    console.log('idin',id)
    setLoading(true)
    await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/${id}`,{
        headers:{
            authorization: `Bearer ${token}`,
        }
    }).then((response)=>{
        console.log(response.data.patientRequest)
        setRequestData(response.data.patientRequest)

        setLoading(false)
    }
    ).catch((error)=>{
        console.log('error',error)
        handleSessionError(error)
        setLoading(false)
    })
    
}
useEffect(()=>{
    getRequestById(requestId)
},[])


  return (
    loading?<Loading/>:
    <>

    <div className='bloodanalysismain'>
    <Navbar/>
<div className="bloodhaead">
                    <div className='leftarrow'>
                        <Icon onClick={()=>navigate(-1)}  className='leftarrowicon' icon="mdi:keyboard-arrow-left" />
                    </div>
                    <div className='paymenttextdiv'>
                        <div> {RequestData.request_title}</div>
                    </div>
                </div>

        <div className="bloodanalysiscontaier">

      <div className='attrachfiles'>الملفات المرفقة</div>
      <div className='imagesshowdiv'>
      {
    RequestData.request_documents && RequestData.request_documents
    .map((file, index) => {
        return <img key={index} src={file} alt={`Document ${index + 1}`} />
    })
}
      </div>
      <div className='descraptiondivtit'>الوصف</div>
      <div className='descraptiondiv'>{RequestData.request_description
}</div>

<div className="doublelinewait">
    {
        RequestData.request_status ==="قيد المعالجة" ? "قيد المعالجة" : "في انتظار مراجعة الملفات والتحاليل"
    }
</div>

{
    RequestData.request_status =="قيد المعالجة"&& <>


<div className='attrachfiles'>التقارير والنظام الغذائي</div>
      <div className='imagesshowdiv'>
{RequestData.replayed_documents&&RequestData.replayed_documents.length>=0?RequestData.replayed_documents.map((file,index)=>{ return( <img src={file} alt={`Documenst ${index +1}`} />)}):""}
      </div>

<div className="buttondivrequestpage">
    <button onClick={downloadReplayedDocuments}>تحميل المفات</button>
</div>

<Chatbox status={RequestData.request_status}/>

</>
}
        </div>
    </div>
    </>
  )
}
