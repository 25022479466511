
import React, { useState, useContext } from 'react';
import './Addcatageries.css';
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from "../../../context/UserContext";
import { Icon } from '@iconify/react/dist/iconify.js';
console.error('Error in celebrating Independence day...')
export default function Adminaddcategory() {
    const { setSuccess, setError, setSuccessAction } = useContext(UserContext);
    const navigate = useNavigate();
    const { section } = useParams();
    const [isOn, setIsOn] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [sectionType, setSectionType] = useState('');
    const [sectionTitle, setSectionTitle] = useState('');
    const [subSections, setSubSections] = useState([]);
    const [formsubmit, setFormsubmit] = useState(false);


    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

    const togglePopup = () => {
        setIsPopupVisible(!isPopupVisible);
    };

    const addSubSection = () => {
        setSubSections([...subSections, { title: '', type: '' }]);
    };

    const handleSubSectionChange = (index, field, value) => {
        const updatedSubSections = subSections.map((subSection, i) =>
            i === index ? { ...subSection, [field]: value } : subSection
        );
        setSubSections(updatedSubSections);
    };

    const handleSubmit = () => {
        setFormsubmit(true);
        const data = {
            sectionTitle,
            sectionType,
            subSections,
        };

        axios.post('/api/section', data)
            .then(response => {
                setSuccess('Section added successfully!');
                navigate('/sections');
            })
            .catch(error => {
                setError('Failed to add section');
            });
    };

    const handleRemoveSubSection = (index) => {
        const updatedSubSections = subSections.filter((_, i) => i !== index);
        setSubSections(updatedSubSections);
      };

const goback = ()=>{
    navigate(-1);
}

    return (
        <div>
            <Navbar />
            <div className="addasectionmaindiv">
            <div className="topnavbardivmain">
<div onClick={goback}><Icon icon="weui:back-filled" width="1.2em" height="1.2em" /></div>
<div>إضافة قسم</div>
</div>

                <div className="maindivflexofaddasection">
                    <div className='departmentnamedivinput'>
                        <p>اسم القسم</p>
                        <input
                            type="text"
                            value={sectionTitle}
                            onChange={(e) => setSectionTitle(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleSubmit();
                                }
                              }}
                            className={formsubmit && !sectionType ? 'input-error' : ''}
                        />
                    </div>

                    <div className='addasectionoptiondiv'>
                        <p>نوع القسم</p>
                        <select
                            value={sectionType}
                            className={formsubmit && !sectionType ? 'input-error' : ''}
                            onChange={(e) => setSectionType(e.target.value)}
                        >
                            <option value="">اختر نوع القسم</option>
                            <option value="الفيديوهات">فيديوهات</option>
                            <option value="الكتب">الكتب</option>
                        </select>
                    </div>
                </div>

                <div className='tooglebtnandh2div'>
                    <div>
                        <h2>إضافة أقسام فرعية</h2>
                    </div>
                    <div className="toggle-container">
                        <div className={`toggle-switch ${isOn ? 'on' : 'off'}`} onClick={toggleSwitch}>
                            <div className="toggle-knob"></div>
                        </div>
                    </div>
                </div>

                {isOn && (
                    <div className='subsection-details'>
                        {subSections.map((subSection, index) => (
                            <div className="maindivflexofaddasection" key={index}>
                                <div className='departmentnamedivinput'>
                                    <div className="crosicondivbutton">
                                    <p> اسم القسم الفرعي</p>
                                    <div className="icondivdesign" onClick={() => handleRemoveSubSection(index)}>
                                    <Icon icon="charm:cross" width="1.2em" height="1.2em" />
                                    </div>


                                    </div>
                                    <input
                                        type="text"
                                        value={subSection.title}
                                        onChange={(e) => handleSubSectionChange(index, 'title', e.target.value)}
                                    />
                                </div>

                                <div className='addasectionoptiondiv'>
                                    <p>نوع القسم</p>
                                    <select
                                        value={subSection.type}
                                        onChange={(e) => handleSubSectionChange(index, 'type', e.target.value)}
                                    >
                                        <option value="">اختر نوع القسم</option>
                                        <option value="الفيديوهات">فيديوهات</option>
                                        <option value="الكتب">الكتب</option>

                                    </select>
                                </div>
                            </div>
                        ))}

                        <div className='plusiconandspanmaindiv'>
                            <span>إضافة قسم فرعي</span>
                            <div onClick={addSubSection}>
                                <Icon style={{background: '#B571AC', color: '#fff', borderRadius: '50px'}} icon="ic:round-plus" />
                            </div>
                        </div>
                    </div>
                )}

                <div className='addasectionbuttondiv'>
                    <button onClick={handleSubmit}>إضافة القسم</button>
                </div>
            </div>

            <Footer />
        </div>
    );
}




