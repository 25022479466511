import React, { useEffect, useState } from "react";
import "./Video.css";

import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams } from "react-router-dom";
import Comments from "./Comments";

import { UserContext } from "../../../context/UserContext";
import { useContext } from "react";
import Share from "../Buyabook/Share";
import axios from "axios";
import { useSessionErrorHandler } from "../Managesession";
import Loading from "../Loading/Loading";

function Video() {
  const handleSessionError = useSessionErrorHandler();
  const [disableReason,setDisableReason] = useState(
    ''
  )
  const { videoID } = useParams();
  const { user,setLoading,loading, setSuccessAction, setSuccess, token, setUsersession } =
    useContext(UserContext);
  const [activeTab, setActiveTab] = useState("description");
  const [like, setLike] = useState();
  const [incLike, setIncLike] = useState(); // Initialize with current like count
  const [popupshow, setPopupshow] = useState(false);
  const [deletepopup, setDeletepopup] = useState(false);
  const [videoDetails, setVideoDetails] = useState({});
  const [sharepop, setSharepop] = useState(false);
  const [comments, setComments] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const navigate = useNavigate();


  const handlevideolike = () => {
    if(!user)
    {
      alert('الرجاء تسجيل الدخول أولا')
      return
    }
    if (like) {
      console.log("unlike");
      axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/videolikes/unlikeVideo/${videoID}?likedBy=${user._id}`,
          {},
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response.data.response);
          setIncLike(response.data.likes);
          setLike(false);
        });
    } else {
      console.log("like");
      axios
        .patch(
          `${process.env.REACT_APP_SERVER_URL}/videolikes/likeVideo/${videoID}?likedBy=${user._id}`,
          {},
          {
            headers: { authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response);
          setIncLike(response.data.likes);
          setLike(true);
        });
    }
  };
  const getVideoLikes = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/videolikes/videoLikedByuser/${videoID}?userId=${user._id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      console.log("video likes", response.data.response);
      setLike(response.data.response);
      setIncLike(response.data.likes);
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  useEffect(() => {
    getVideoLikes();
  }, []);

  const getVideoDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/video/getVideo/${videoID}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      console.log("video details", response.data.response);
      const formattedDate = new Date(
        response.data.response.created_at
      ).toLocaleDateString("en-GB");
      setVideoDetails({ ...response.data.response, created_at: formattedDate });
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  useEffect(() => {
    getVideoDetails();
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const disableVideo = async (e, id) => {
    try {
      setLoading(true)
        setDeletepopup(false);
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/video/videoDisable/${videoID}?disableBy=${user._id}`,
          {disableReason:disableReason}
        , {
            headers: { "authorization": `Bearer ${token}` }
        }).then((response)=>{
          console.log('response',response)
          setLoading(false)
          setDisableReason('')
          setSuccess('Video Deleted Successfully')
          setSuccessAction('/')
          navigate('/success')
        })
    } catch (err) {
      setLoading(false)
        handleSessionError(err);
        console.log(err);
    }
}
const updateVideoToHidden = async (e) => {
  e.stopPropagation();
  try {
    await axios.patch(
      `${process.env.REACT_APP_SERVER_URL}/video/updateToHidden/${videoID}`,
      {},
      {
        headers: { authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      setVideoDetails(response.data.response);

    });

    // Set the tab and fetch videos after the status has been changed
  } catch (err) {
    handleSessionError(err);
    console.log(err);
  }
};
  const gotobuybook = () => {
    navigate("/buybook");
  };
  const unhidevideo = async (e, id) => {
    e.stopPropagation();
    try {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}/video/updateFromHiddenToShow/${videoID}`, {}, {
            headers: { "authorization": `Bearer ${token}` }
        }).then((response) => {
            console.log(response);
            setVideoDetails(response.data.response);
        });


    } catch (err) {
        handleSessionError(err);
        console.log(err);
    }
};
  const gotodashboard = () => {
    navigate("/");
  };

  const getAllComments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/Videocomments/getAllComments/${videoID}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      console.log("All Comments", response.data);
      setComments(response.data.comments);
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  useEffect(() => {
    if (activeTab === "comments") {
      getAllComments();
    }
  }, [activeTab]);

  const deleteComment = async (commentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}/Videocomments/deleteComment/${videoID}/${commentId}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      setComments(comments.filter((comment) => comment._id !== commentId));
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  const unlikeComment = async (commentId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/Videocomments/unlikeComment/${videoID}/${commentId}`,
        { unlikedBy: user._id },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      console.log("unlike comment", response.data);

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId ? response.data.response : comment
        )
      );
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  const likeComment = async (commentId) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_SERVER_URL}/Videocomments/likeComment/${videoID}/${commentId}`,
        { likedBy: user._id },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      console.log("like comment", response.data.data);

      setComments((prevComments) =>
        prevComments.map((comment) =>
          comment._id === commentId ? response.data.data : comment
        )
      );
    } catch (err) {
      console.log(err);
      handleSessionError(err);
    }
  };

  const showpopup = (e) => {
    e.stopPropagation();
    setPopupshow(!popupshow);
  };

  const showdelete = (e) => {
    e.stopPropagation();
    setDeletepopup(!deletepopup);
  };
  const deleteVideo = async() =>{
    try{
      setLoading(true)
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}/video/delete/${videoID}`,{
        headers:{
          authorization:`Bearer ${token}`
        }
      }).then((response)=>{
          console.log(response)

          setSuccess('Video Deleted Successfully')
          setSuccessAction("/")
          navigate('/success')
          setLoading(false)
      }).catch((err)=>{
        console.log(err)
        setLoading(false)
        navigate(-1)

      })

    }catch(err){
      console.log(err)
    }
  }

  const performdeletaction = () => {
    // delete the course from the database or any other required actions
    setDeletepopup(false);
    if(user?.role==='admin'){
      disableVideo()
    }else if(user?.role ==='super-admin'){
      deleteVideo()
    }
  };

  const navigatesucess = (e) => {
    e.stopPropagation();
    navigate("/success");
    setSuccessAction("/");
    setSuccess("success message");
  };

  const gotouplaodvideo = (e, id) => {
    e.stopPropagation();
    navigate(`/uploadvideo/${id}`);
  };

  const tooglesharepopup = () => {
    setSharepop(!sharepop);
  };
 const recoverVideo  =async ( ) =>{
  try{
    await axios.patch(`${process.env.REACT_APP_SERVER_URL}/video/updateVideoFromDisable/${videoID}`,{},{
      headers:{
        authorization:`Bearer ${token}`
      }
    }).then((response)=>{
      console.log(response)
      setVideoDetails(response.data.response)
    })


  }catch(err){
    console.log(err)
  }


 }
  const addComment = async (newComment) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/Videocomments/addComment/${videoID}`,
        {
          comment: newComment,
          commentedBy: user._id,
        },
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      getAllComments();
    } catch (err) {
      handleSessionError(err);
      console.log(err);
    }
  };

  const getEmbedUrl = (url) => {
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
    } else {
      return ""; // Or display a user-friendly message for invalid URLs
    }
  };

  const truncatedDescription = showAll
    ? videoDetails.description
    : videoDetails.description?.substring(0, 300) || "";

  const showMore = () => {
    setShowAll(!showAll);
  };

  return (
    loading?<Loading/>:
    <>

      <div className="videosdivmain">
        {sharepop && (
          <>
            <div onClick={tooglesharepopup} style={{ position: "fixed" }}>
              <Share />
            </div>
          </>
        )}

        {deletepopup && (
          <>
            <div className="deletemaindiv">
              <div className="deletecontainer">
              <div className="deletetitle">
                <div>سبب الحذف</div>
                <div className="CloseHadafPopup" onClick={()=>setDeletepopup(false)}>x</div>
              </div>

              <textarea
                  placeholder="مختلف عن موضوع القسم"
                  value={disableReason}
                  onChange={(e) => setDisableReason(e.target.value)}
                />
                <button onClick={performdeletaction}>
                  <Icon
                    icon="ic:baseline-delete"
                    width="1.2em"
                    height="1.2em"
                  />
                  حذف{" "}
                </button>
              </div>
            </div>
          </>
        )}

        <div className="videosnavbar">
          <Navbar />
        </div>

        <div className="videoscontainer">
          <div className="linktonavigate" onClick={gotodashboard}>
            الصفحة الرئيسية {">"} <span>{videoDetails.title}</span>
          </div>

          <div className="videoandlikeshare">
            <div className="videodivshowvideo">
              {videoDetails.youtubeVideoUrl && <></>}

              {videoDetails.youtubeVideoUrl &&
                videoDetails.youtubeVideoUrl !== "" &&
                videoDetails.youtubeVideoUrl != "false" && (
                  <iframe
                    width="100%"
                    style={{ borderRadius: "12px" }}
                    height="100%"
                    src={getEmbedUrl(videoDetails.youtubeVideoUrl)}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                )}
              {videoDetails.videoUrl &&
                videoDetails.videoUrl != "false" &&
                videoDetails.videoUrl !== "" && (
                  <video
                    controls
                    className="videodivshowvideo"
                    src={videoDetails.videoUrl}
                  ></video>
                )}
            </div>

            <div className="likesharemain">
              <div className="likesharediv">
                <div className="share" onClick={tooglesharepopup}>
                  <Icon icon="ph:share-fat-fill" width="1.2em" height="1.2em" />
                </div>

                <div
                  className={like ? "activelike" : "like"}
                  onClick={handlevideolike}
                >
                  <Icon icon="ph:heart-fill" width="1.2em" height="1.2em" />{" "}
                  {incLike ==0? '':incLike}
                </div>
              </div>

              <div className="videotitle">
                {videoDetails.title}
                {user && user.role === "admin" && (
                  <>
                    <div>
                      <Icon
                        onClick={showpopup}
                        icon="bi:three-dots-vertical"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                    {popupshow && (
                      <>
                        <div className="popupmain showvideopopup">
                          <div
                            onClick={(e) =>
                              gotouplaodvideo(e, videoDetails._id)
                            }
                          >
                            تعديل{" "}
                          </div>
                         { videoDetails?.hidden ?<div onClick={unhidevideo} >إظهار
 </div> : <div onClick={updateVideoToHidden}>إخفاء </div>}
                          <div onClick={showdelete}>حذف </div>
                        </div>
                      </>
                    )}
                  </>
                )}    {user && user.role === "super-admin" && (
                  <>
                    <div>
                      <Icon
                        onClick={showpopup}
                        icon="bi:three-dots-vertical"
                        width="1.2em"
                        height="1.2em"
                      />
                    </div>
                    {popupshow && (
                      <>
                        <div className="popupmain showvideopopup">

                          <div onClick={showdelete}>حذف </div>
                         { videoDetails.disable&& <div onClick={recoverVideo}>يتعافى </div>}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>



          <div className="routesnavdiv">
            <div
              className={activeTab === "description" ? "active" : ""}
              onClick={() => handleTabClick("description")}
            >
              الوصف
            </div>
            <div
              className={activeTab === "comments" ? "active" : ""}
              onClick={() => handleTabClick("comments")}
            >
              التعليقات
            </div>
          </div>

          <div className="showroutescontent">
            {activeTab === "description" && (
              <div className="desxraptionmaindiv">
                <div> تم نشره:{videoDetails.created_at}</div>
                <div>{truncatedDescription}</div>
                {truncatedDescription.length < 300 ? (
                  <></>
                ) : (
                  <>
                    {truncatedDescription !== videoDetails.description && (
                      <div onClick={showMore}>...المزيد</div>
                    )}
                    {truncatedDescription === videoDetails.description && (
                      <div onClick={showMore}>تظهر أقل </div>
                    )}
                  </>
                )}
              </div>
            )}
            {activeTab === "comments" && (
              <>
                <Comments
                  addComment={addComment}
                  comments={comments}
                  user={user}
                  likeComment={likeComment}
                  deleteComment={deleteComment}
                  videoID={videoID}
                  unlikeComment={unlikeComment}
                />
              </>
            )}
          </div>
          <div className="subractionbutton">
            <button>متابعة قسم تجريبي</button>
          </div>
        </div>

        <div className="videosfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Video;
