

import React, { useState } from 'react';
import './Splach_screen.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';
import axios from 'axios';
import Loading from '../Loading/Loading';

export default function ResetChangepassword() {
  const { setSuccess, setError, setSuccessAction,loading,setLoading } = useContext(UserContext);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [submitAttempt, setSubmitAttempt] = useState(false); // State for tracking form submission
  const navigate = useNavigate();

  const handleSave = async() => {

    setSubmitAttempt(true); // Mark form as submitted

    // Simple validation
    if (newPassword && confirmNewPassword) {
      if (newPassword === confirmNewPassword) {
        // Navigate to success screen
        setLoading(true)
           await axios.patch(`${process.env.REACT_APP_SERVER_URL}/user/byPhonePassReset`,{reset_pass:newPassword,confirm_reset_pass:confirmNewPassword,phone_number:localStorage.getItem('phone_number')}).then((res)=>{
            console.log(res)
            if(res.status==200){

        setSuccess("reset password success");
        setSuccessAction('/');
        localStorage.removeItem('phone_number')
        setLoading(false)
        navigate('/success');
            }
           }).catch((err)=>{
            setError("reset password error");
            setLoading(false)
            navigate('/error');

            console.log(err)

           })


      } else {
        // Navigate to error screen
        navigate('/error');
        setError("reset password error");
      }
    } else {
      // Navigate to error screen
    //   navigate('/error');
    }
  };

  return (
    loading?<Loading/>:
    <div>
     <SimpleNavbar/>

      <div className='splach_screenmaindiv'>
        <h1>تغيير كلمة المرور</h1>

        <p>كلمة المرور الجديدة</p>
        <input
          type="password"
          placeholder='بالرجاء ادخال كلمة المرور الجديدة'
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSave();
            }
          }}
          className={submitAttempt && !newPassword ? 'input-error' : ''}
        />

        <p>إعادة كلمة المرور الجديدة</p>
        <input
          type="password"
          placeholder='بالرجاء ادخال كلمة المرور الجديدة'
          value={confirmNewPassword}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSave();
            }
          }}
          onChange={(e) => setConfirmNewPassword(e.target.value)}
          className={submitAttempt && !confirmNewPassword ? 'input-error' : ''}
        />

        <button onClick={handleSave}>حفظ</button>
      </div>

      <Footer />
    </div>
  );
}
