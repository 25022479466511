import React from 'react'
import './Sessionexpire.css'
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

function Sessionexpire() {

    const {setUsersession,usersession ,setUser,setToken} = useContext(UserContext);

const navigate = useNavigate();

const gotologin = ()=>{
    setUsersession(false);
  setUser(null);
  setToken(null);
  localStorage.removeItem("user");
  localStorage.removeItem("token");
    navigate("/login");
}

  return (
    <>
    <div className="sessionexpiremain">

<div className="sessionexpirecontainer">
    <div>انتهت الجلسة</div>
    <div>بالرجاء تسجيل الدخول مرة اخرى</div>
    <div><button onClick={gotologin}>تم</button></div>
</div>
    </div>
    </>
  )
}

export default Sessionexpire;