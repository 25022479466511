import React, { useState, useEffect, useContext } from "react";
import "./Uploadvideo.css";
import Navbar from "../../User/Navbar/Navbar";
import Footer from "../../User/Footer/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import axios from "axios";
import Loading from "../../User/Loading/Loading";
import { useSessionErrorHandler } from "../../User/Managesession";

function Uploadvideo() {

  const handleSessionError = useSessionErrorHandler();

  const { setLoading, user,loading, setSuccessAction, setSuccess, token } =
    useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState("");
  const [youtubeLink, setYoutubeLink] = useState("");
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(false);
  const [existingvideoUrl, setExistingvideoUrl] = useState("");

  useEffect(() => {
    if (id) {
      console.log(id);
      const fetchVideoData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}/video/getVideo/${id}`,{headers : {authorization : `Bearer ${token}`}}
          );
          console.log(response);
          const videoData = response.data.response;
          if(videoData.youtubeVideoUrl){
          setYoutubeLink(videoData.youtubeVideoUrl || "");
          }
          setVideoTitle(videoData.title || "");
          setVideoDescription(videoData.description || "");
          if(videoData.videoUrl){
          setVideoPreviewUrl(videoData.videoUrl || "");
          }
        } catch (error) {
          console.error("Error fetching video data:", error);
          handleSessionError(error);
        }
      };

      fetchVideoData();
    }
  }, []);

  const goback = () => {
    navigate(-1);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoFile(file);
      setExistingvideoUrl(videoUrl);
      setVideoPreviewUrl("")// Clear video preview URL if a video file is selected
      setYoutubeLink(""); // Clear YouTube link if a video file is selected

      console.log("YouTube Link:", youtubeLink);
      console.log("Existing Video URL:", videoUrl);
    }
  };

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleSubmit = async () => {
    console.log(youtubeLink)
    setSubmitAttempt(true);
    console.log('handle')
    if (

      videoTitle &&
      videoDescription

    ) {
      const formData = new FormData();
      if(youtubeLink){
      formData.append("youtubeLink", youtubeLink);
      }
      formData.append("title", videoTitle);
      formData.append("description", videoDescription);
      if (videoFile) {
        formData.append("file", videoFile);
      }
console.log("formData",formData);
      try {
        setLoading(true);
        const response = await axios.put(
          `${process.env.REACT_APP_SERVER_URL}/video/update/${id}?adminId=${user._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        if (response.status === 200) {
          setLoading(false);
          setSuccessAction(`/videos/${response.data.data._id}`);
          setSuccess("Video updated successfully");
          navigate("/success");
        }
      } catch (error) {
        handleSessionError(error);
        setLoading(false);

        console.error("Error uploading/updating video:", error);
      }
    }
    else{
      alert('يرجى ملء جميع الحقول')
    }
  };

  const getEmbedUrl = (url) => {
    const videoIdMatch = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/|.*[?&]v=)|youtu\.be\/)([^#&?]*)/
    );

    if (videoIdMatch && videoIdMatch[1].length === 11) {
      return `https://www.youtube.com/embed/${videoIdMatch[1]}`;
    } else {
      return "";
    }
  };

  return (
    <>
      {loading && <Loading />}
      <div className="uploadvideomain">
        <div className="uploadvideonavbar">
          <Navbar />
        </div>
        <div className="uploadvidocontainer">
          <div className="topupernavbar">
            <div>
              <Icon
                onClick={goback}
                icon="ep:arrow-left-bold"
                width="1.2em"
                height="1.2em"
              />
            </div>
            <div>اضافة فيديو جديد</div>
          </div>

          <div className="video">
            {existingvideoUrl && (
              <video controls>
                <source src={existingvideoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          {
    youtubeLink &&youtubeLink !==""&& youtubeLink !='false' && (
        <iframe
            width="100%"
            style={{borderRadius:"12px"}}
            height="100%"
            src={getEmbedUrl(youtubeLink)}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"  
            referrerPolicy="strict-origin-when-cross-origin"
            allowfullscreen
        ></iframe>
    )}{  (
      videoPreviewUrl && videoPreviewUrl !=="false" && videoPreviewUrl !==""&&(
            <video controls className="videodivshowvideo" src={videoPreviewUrl}></video>
        )
    )
}
          </div>

          {youtubeLink !== "false" &&youtubeLink!==""  ? (
            <>
              <div className="uploadyoutubevid">
                <label>رابط الفيديو من اليوتيوب</label>
                <input
                  type="link"
                  placeholder="https://........"
                  value={youtubeLink}
                  onChange={handleInputChange(setYoutubeLink)}
                  className={submitAttempt && !youtubeLink ? "input-error" : ""}
                />
              </div>
            </>
          ) : (
            ""
          )}

        {videoPreviewUrl&& videoPreviewUrl != "false" &&videoPreviewUrl!=="" ? (
            <>
              <div className="uplaodavideophone">
                <label htmlFor="videoUpload">ارفع فيديو من الجهاز</label>
                <input
                  type="file"
                  id="videoUpload"
                  accept="video/*"
                  style={{ display: "none" }}
                  onChange={handleVideoChange}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div className="titleofvideo">
            <label>عنوان الفيديو</label>
            <input
              type="text"
              placeholder="عنوان الفيديو"
              value={videoTitle}
              onChange={handleInputChange(setVideoTitle)}
              className={submitAttempt && !videoTitle ? "input-error" : ""}
            />
          </div>

          <div className="descraptionofvideo">
            <div className="descrationvideoof">الوصف</div>
            <div className="belowdivwithborder">
              <textarea
                placeholder="الوصف"
                rows={10}
                cols={10}
                value={videoDescription}
                onChange={handleInputChange(setVideoDescription)}
                className={
                  submitAttempt && !videoDescription ? "input-error" : ""
                }
              />
            </div>
          </div>

          <div className="buttonbelowdiv">
            <button onClick={handleSubmit}>حفظ</button>
          </div>
        </div>

        <div className="uploadvideofooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Uploadvideo;
