import React, { useContext, useState } from 'react'
import samplePic from './../../../../../../Asserts/Images/test.png'
import { ManagersContext } from '../../../ManagersManagementContext/ManagersContext'
import { useNavigate } from 'react-router-dom'

function ManagerVideos(activities ) {
  const navigate = useNavigate()
  console.log("activity",activities)
  const {activityitem} = useContext(ManagersContext)
  const [activityVideos,setActivitVideos] = useState(activities.activityVideos)

  return (
    <div className='managerdetails-video-container'>
      {
        activityVideos&& activityVideos?.length>0? activityVideos?.map((item)=>{
          return (
            <div onClick={()=>navigate(`/videos/${item._id}`)} className='managerdetails-video-box'>
        <div className='managerdetails-video-box-left'>
          <img src={samplePic} alt="Doctor" />
        </div>
        <div className='managerdetails-video-box-right'>
           <div className="managerdetails-right-titletime">
              <div className="managerdetails-right-heading">{item.title}</div>
              <div className="managerdetails-right-time">{item.duration}</div>
            </div>
          <div className='managerdetails-right-description'>{item.description}</div>
        </div>
      </div>
          )
        })
     :"No activities yet..." }
    </div>
  )
}

export default ManagerVideos
