
import React, { useState, useContext } from 'react';
import './Callus_form.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../Loading/Loading';
import SimpleNavbar from '../SimpleNavbar/SimpleNavbar';

export default function Callusform() {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submitAttempt, setSubmitAttempt] = useState(false);

  const { setSuccess, setSuccessAction, setError ,loading,setLoading} = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitAttempt(true);

    if (name && phone && email && subject && message) {
      try {
        setLoading(true)
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sendemail`, {
          name: name,
          phone: phone,
          sender_email: email,
          subject: subject,
          message: message,
        });

        console.log({ name, phone, email, subject, message });
        setSuccess('تم إرسال الرسالة بنجاح');
        setSuccessAction('/callus');
        setLoading(false)
        navigate('/success');
        console.log(res);
      } catch (err) {
        console.log(err);
        setSuccess('حدث خطأ، يرجى إعادة المحاولة');
        setSuccessAction('/callus');
        setLoading(false)
        navigate('/error');
      }
    } else {
      setSuccess('حدث خطأ، يرجى إعادة المحاولة');
      setError("error of call form");
    //   navigate('/error');
    }
  };

  return (
    loading ? <Loading/> :
    <div className='callustopmaindiv'>
      <SimpleNavbar/>
      {/* <Navbar/> */}
      <div className='Callus_form_maindiv'>
        <h1>اتصل بنا</h1>
        <h2>عبر ملئ النموذج التالي وسنرد عليكم في أسرع وقت ممكن</h2>
        <form >
          <p>الاسم</p>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={submitAttempt && !name ? 'input-error' : ''}
          />
          <p>رقم الجوال</p>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className={submitAttempt && !phone ? 'input-error' : ''}
          />
          <p>البريد الإلكتروني</p>
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={submitAttempt && !email ? 'input-error' : ''}
          />
          <p>الموضوع</p>
          <input
            type="text"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            className={submitAttempt && !subject ? 'input-error' : ''}
          />
          <p>رسالتك</p>
          <textarea
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className={submitAttempt && !message ? 'input-error' : ''}
          ></textarea>
          <div id='callusbtncenterdiv'>
            <button type="submit" onClick={handleSubmit}>إرسال</button>
          </div>
        </form>
      </div>
      <div className="callusfooter">
        <Footer />
      </div>
    </div>
  );
}
