import React, { useContext } from 'react'
import './interviewdetails.css'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { socket } from '../Bloodimageanalysis/globalsocket'
import { useSessionErrorHandler } from '../Managesession'
import { UserContext } from '../../../context/UserContext'
function Messagemeetiglink({setMeetingData,setLoading,loading,handlemeeetingpop,gotosuccess }) {
  const {interviewID} = useParams()
  const {token} = useContext(UserContext)
  const handleSessionError = useSessionErrorHandler();
  const updateMeetingStatus = async(status)=>{
    try{
        setLoading(true)
      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/meeting/updateMeeting/${interviewID}?meeting_status=${status}`,{},{headers:{
        authorization:`Bearer ${token}`
      }}).then((response)=>{
        const socketData ={
          Link:`/Interviewdetail/${response.data.data._id}`,
          Message:`اجتماعك ${status} دكتور مازن`,
          userID:response.data.data.Meeting_Req_User._id,
          Deliver_To:'user',
          created_at:Date.now()
        }
        socket.emit('notification',socketData)
        console.log("updated Response",response)
        setMeetingData(response.data.data)

        setLoading(false)
      }).catch((err)=>{
        handleSessionError(err)
        console.log(err)
        setLoading(false)
      })
    }catch(err){
      console.log(err)
    }
  }
  return (
  <>

  <div className="inputsdivmeetingmain">

<div className='interviewtexth2'>
        <h2> تأكيد المعاد</h2>
      </div>


<div className="testareadivflex">
<label htmlFor="">رسالتك </label>
<textarea name="" id=""></textarea>
</div>

<div className="inputdivflexdiv">
<label htmlFor="">رابط اللقاء </label>
<input type="text" />
</div>

<div className="buttonsmaindiv2">
<button onClick={()=>updateMeetingStatus("اللقاءات المرفوضة")}>رفض اللقاء</button>
<button onClick={()=>updateMeetingStatus("لقاءات غير مدفوعة")} >تأكيد اللقاء</button>
</div>

</div>

  </>
  )
}

export default Messagemeetiglink;