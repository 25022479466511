import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';

export default function UnpaidLabworks() {
    const { user, token } = useContext(UserContext);
    const [unpaidLabworks, setUnpaidLabworks] = useState([]);
    const [page, setPage] = useState(1);  // current page state
    const [limit] = useState(10);  // limit per page, fixed at 10 for now
    const [totalPages, setTotalPages] = useState(1);  // total pages available

    // Fetch unpaid lab works with pagination
    const getAllUnpaidLabWorks = async (page) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/unpaidLabworks`, {
                headers: {
                    authorization: `Bearer ${token}`
                },
                params: { page, limit }
            });

            console.log('unpaid labworks:', response.data.requests);

            setUnpaidLabworks(response.data.requests);
            setTotalPages(response.data.totalPages);  // update total pages
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (user?.role === 'admin') {
            getAllUnpaidLabWorks(page);  // fetch data when page changes
        }
    }, [page, user]);

    // Handle page navigation
    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div>
            <h2>Unpaid Labworks</h2>
            {unpaidLabworks.length > 0 ? (
                <ul>
                    {unpaidLabworks.map(labwork => (
                        <li key={labwork._id}>{labwork?.requested_by?.first_name}</li>  // replace someDetail with actual property
                    ))}
                </ul>
            ) : (
                <p>No unpaid labworks found.</p>
            )}

            {/* Pagination controls */}
            <div className="pagination-controls">
                <button onClick={handlePrevPage} disabled={page === 1}>
                    Previous
                </button>
                <span>Page {page} of {totalPages}</span>
                <button onClick={handleNextPage} disabled={page === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}
