import React , {useState} from 'react'
import './Paymentsuccess.css'
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';

function Paymentsuccess() {

    const navigate = useNavigate();

const [shwoinvoice, setShowinvoice] = useState(false);

const toogelinvoice = ()=>{
    setShowinvoice(!shwoinvoice);
}

const gotomymeetings =()=>{
    navigate("/mymeeting")
}

  return (
  <>
  <div className="paymentsuccesmain">
<div className='tickicondiv'><Icon icon="charm:tick" /></div>
<div className='sucesetitle'>تم الدفع بنجاح </div>
<div className='successdecrap'>سيتم التواصل معكم عبر الرسائل النصية
و خدمة التنبيهات في الموقع لأجل
خطوات المتابعة</div>
<div className='openingbuttond' onClick={toogelinvoice}>عرض الفاتورة {
    shwoinvoice ? <>
<Icon icon="flowbite:angle-top-solid" />

    </> : <>
    <Icon icon="iconoir:nav-arrow-down" />
    </>} 
</div>
{
    shwoinvoice && <>
<div className={shwoinvoice ? "opentingdiv" :'closediv'}>

<div className="containermaindiv">

<div className="ammounttitle">
    2000 SR
</div>

<div className="divmainflex">
    <div className='invoiceleft'>Sender</div>
    <div className='invoiceright'>
<div>MOHAMED ...</div>
<div>****...1234</div>
    </div>
</div>



<div className="divmainflex borderbottom">
    <div className='invoiceleft'>Receiver</div>
    <div className='invoiceright'>
    MAZEN ACADEMY
    </div>
</div>


<div className="divmainflex">
    <div className='invoiceleft'>Amount</div>
    <div className='invoiceright'>
    2000 SAR
    </div>
</div>


<div className="divmainflex">
    <div className='invoiceleft'>Service Fee</div>
    <div className='invoiceright'>
    0 SAR
    </div>
</div>

<div className="divmainflex">
    <div className='invoiceleft'>References Number</div>
    <div className='invoiceright'>
    000085752257
    </div>
</div>


<div className="divmainflex">
    <div className='invoiceleft'>Date</div>
    <div className='invoiceright'>
    Aug 24, 2024
    </div>
</div>


<div className="divmainflex">
    <div className='invoiceleft'>Time</div>
    <div className='invoiceright'>
    07:80 AM
    </div>
</div>


<div className="divmainflex borderbottom">
    <div className='invoiceleft'>Payment Method</div>
    <div className='invoiceright'>
    Credit Card
    </div>
</div>


<div className="downloadbuttondiv">
<Icon icon="hugeicons:download-01" />
Get Receipt
</div>



</div>

</div>
    </>
}
<div className='buttondivbut' onClick={gotomymeetings}>تم</div>



  </div>
  </>
  )
}

export default Paymentsuccess;