import React, { useState } from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'
import './PaymentSuccess.css'

function PaymentSuccess() {
    const [show,setShow] = useState(false)
  return (
    <div>
      
      <div className='paymentsuccess-container'>
        <div className='paymentsuccess-successmessage'>
            <div className='success-icon'>
              <Icon icon="hugeicons:tick-02" width="1.2em" height="1.2em" />
            </div>
            <div className='success-message'>
            تم الدفع بنجاح 
            </div>
        </div>
        <div>
            <div className='showmore-success' onClick={()=>{setShow(!show)}}>
                <div className='sidedotted-line'></div>
                <div>عرض الفاتورة </div>
                <div>
                    {
                        show ?  <Icon icon="mingcute:up-line" />: <Icon icon="mingcute:down-line"/>
                    }
                    </div>
                <div className='sidedotted-line'></div>
            </div>
            {
                show ? <>
<div className='success-bill-details'>
                <div>
                    <h1>2000 SR</h1>
                </div>
                <div className='dotted-line'></div>
                <div className='data-section'>
                    <div className='line-data'>
                        <div className='first-element'>المشتري</div>
                        <div>المشتري</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>البائع</div>
                        <div>البائع</div>
                    </div>
                </div>
                <div className='dotted-line'></div>
                <div className='data-section'>
                    <div className='line-data'>
                        <div className='first-element'>المبلغ</div>
                        <div>200</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>رسوم إضافية</div>
                        <div>200</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>الرقم المرجعي</div>
                        <div>200</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>التاريخ</div>
                        <div>200</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>الوقت</div>
                        <div>200</div>
                    </div>
                    <div className='line-data'>
                        <div className='first-element'>وسيلة الدفع</div>
                        <div>200</div>
                    </div>
                </div>
                <div className='dotted-line'></div>
                <div className='reciept-download'>
                    <div><Icon icon="hugeicons:download-01"/></div>
                    <div>تحميل الفاتورة</div>
                </div>
                <div className='bold-line'></div>
            </div>
                </> : <></>
            }
            
        </div>
        <div className='end-button'>
            <button>تم</button>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccess
