import React, { useState, useContext } from 'react';
import './Progressadr.css';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Navbar from '../Navbar/Navbar';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from 'axios'
import Loading from '../Loading/Loading';
import { socket } from '../Bloodimageanalysis/globalsocket';
import moment from 'moment';

import { useSessionErrorHandler } from '../Managesession';
function Bookmeeting() {
  const handleSessionError = useSessionErrorHandler();
  const navigate = useNavigate();
  const { setActiveButtonmet, setError, setSuccessAction, token,activeButtonmet } = useContext(UserContext);
  const {user,setLoading,loading} = useContext(UserContext)
  const [value, setValue] = useState(new Date());
  const [showPopState, setShowPopState] = useState(false);
  const [activeItem, setActiveItem] = useState(""); // Track selected item (initially null)
  const [descraption, setDescraption] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(false);

  const handleClick = (time) => {
    setActiveItem(time);
  };

  const scheduleData = [
    { time: "10:00" },
    { time: "9:00" },
    { time: "8:00" },
    { time: "13:00" },
    { time: "12:00" },
    { time: "11:00" },
    { time: "16:00" },
    { time: "15:00" },
    { time: "14:00" },
    { time: "19:00" },
    { time: "18:00" },
    { time: "17:00" },
    { time: "20:00" },
  ];

  const handleDateChange = (date) => {
    setValue(date);
    setShowPopState(true); // Set pop state true on date selection
  };

  const handleButtonClick = (buttonName) => {
    setActiveButtonmet(buttonName);
    navigate("/readanalysis");
  };

  const bookmeeting = async () => {

    setSubmitAttempt(true);
    if (descraption && value && activeItem) {
      try {
        setLoading(true)
        const [hours, minutes] = activeItem.split(':').map(Number);
      const combinedDateTime = new Date(value);

      // Set the hours and minutes
      combinedDateTime.setHours(hours);
      combinedDateTime.setMinutes(minutes);
      combinedDateTime.setSeconds(0);  // Optionally set seconds to 0
      combinedDateTime.setMilliseconds(0);  // Optionally set milliseconds to 0

      // Convert to ISO string (e.g., "2024-08-13T10:53:30.000Z")
      const formattedDateTime = combinedDateTime.toISOString()
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/meeting/createMeeting`, {
          Meeting_Req_User: user._id,
          Meeting_Time: formattedDateTime,
          time: activeItem,
          Meeting_description: descraption,
        },{
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        console.log("meeting response",response)
        if (response.status === 200) {
          const socketData = {
            Message:`مطلوب لاجتماع التكبير ${user.first_name}`,
            Link:`/Interviewdetail/${response.data.response._id}`,
            Deliver_To:'admin',
            created_at:Date.now()

          }
          socket.emit('notification',socketData)
          setError("");
          setLoading(false)
          setSuccessAction("/");
          navigate(`/Interviewdetail/${response.data.response._id}`);
        } else {
          setLoading(false)
          handleSessionError(response);
          setError("Error booking meeting. Please try again.");
        }
      } catch (error) {
        setLoading(false)
        handleSessionError(error);
        setError("Error booking meeting. Please try again.");
        console.error(error);
      }
    } else {
      setError("Please fill out all fields.");
    }
  };
  return (
    loading?<Loading/>:
    <>
      <div className="bookameetingmain">
        <div className="bookmeetingnavbar">
          <Navbar />
        </div>

        <div className="bookmeetingcontainer">
          <div className="readanalysisbuttonmaindiv">
            <button
              className="simplebuttomn"
              onClick={() => handleButtonClick( "قراءة تحليل")}
            >
              قراءة تحليل
            </button>
            <button className="active2">
              لقاء مع الدكتور أونلاين
            </button>
          </div>

          <div className="calendermaindiv">
            <Calendar
              onChange={handleDateChange}
              locale="ar"
              value={value}
              minDate={new Date()} // Disable past dates
            />
            {showPopState && (
              <>
                <div className="showlinediv">
                  المواعيد المتاحة
                </div>
                <div className="datesselectmain">
                  {scheduleData.map((schedule, index) => (
                    <button
                      key={index}
                      className={
                        (submitAttempt && !activeItem) || activeItem === schedule.time
                          ? activeItem === schedule.time
                            ? "activatetim"
                            : "input-error"
                          : ""
                      }
                      onClick={() => handleClick(schedule.time)}
                    >
                      {schedule.time}
                    </button>
                  ))}
                </div>
                <div className="textdescraption">
                  <label htmlFor="">الوصف</label>
                  <textarea
                    name="descraption"
                    value={descraption}
                    onChange={(e) => setDescraption(e.target.value)}
                    className={submitAttempt && !descraption ? "input-error" : ""}
                  ></textarea>
                </div>
                <div className="Analysisrequestbtn">
                  <button onClick={bookmeeting}>طلب لقاء</button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bookmeetingfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Bookmeeting;
