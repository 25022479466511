import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import './latestmods.css'
import VideosEdited from './LatestModsComponents/VideosEdited/VideosEdited'
import VideosDeleted from './LatestModsComponents/VideosDeleted/VideosDeleted'
import CommentsDeleted from './LatestModsComponents/CommentsDeleted/CommentsDeleted'
import { Icon, loadIcon } from '@iconify/react/dist/iconify.js'
import { UserContext } from '../../../context/UserContext'
import axios from 'axios'
import { useSessionErrorHandler } from '../../User/Managesession'
import Loading from '../../User/Loading/Loading'

function LatestMods() {
  const [mod,setMod] = useState('videoedited')
  const {user,token,setLoading,loading} = useContext(UserContext)
  const handleSessionError = useSessionErrorHandler()
  const [data,setData] = useState([])
  const getAllAdminsAndTheirActivities = async()=>{
    try{
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/admin/allAdminsAndTheirActivities`,{
        headers:{
          authorization:`Bearer ${token}`
        }
      })
      console.log("all admins",response.data.response)
      setData(response.data.response)
      setLoading(false)
    }catch(err){
      console.log(err)
      handleSessionError(err)
      setData([])
      setLoading(false)
    }
  }
  useEffect(()=>{
    if(user?.role==='super-admin'){
    getAllAdminsAndTheirActivities()
    }
  }
  ,[])




  return (
    loading?<Loading/>:
    <div>
        <div><Navbar/></div>
        <div className='latestmods-body'>
            {/* <div className='latestmods-head'><div>إضافة مدير</div></div> */}
            <div className='latestmods-buttons'>


                    <div className={ mod === 'videoedited' ? 'lmb-active' : 'lmb' }><button onClick={()=>{setMod('videoedited')}}>الفيديوهات المعدلة</button></div>
                    <div className={ mod === 'videodeleted' ? 'lmb-active' : 'lmb' }><button onClick={()=>{setMod('videodeleted')}}>الفيديوهات المحذوفة</button></div>
                    {/* <div className={ mod === 'commentdeleted' ? 'lmb-active' : 'lmb' }><button onClick={()=>{setMod('commentdeleted')}}>التعليقات المحذوفة</button></div> */}


            </div>
            <div>
                { mod === 'videoedited' ? <VideosEdited videosEdited={data} /> : <></> }
                { mod === 'videodeleted' ? <VideosDeleted videosDeleted={data} /> : <></> }
                {/* { mod === 'commentdeleted' ? <CommentsDeleted commentsDeleted={data?.} /> : <></> } */}
            </div>
        </div>
        <><div className="whatappicon">
                <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
            </div></>
        <div><Footer/></div>
    </div>
  )
}

export default LatestMods
