
import React, { useState, useEffect, useContext } from 'react';
import './Dashboard.css';
import Navbar from '../Navbar/Navbar';
import { Icon } from '@iconify/react';
import drimg from '../../../Asserts/Images/drimg.svg';
import Book from '../Book/Book';
import { UserContext } from '../../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import Slider from '../Slider/Slider';
import axios from 'axios';
import Loading from '../Loading/Loading';
import Pinnedcard from './Pinnedcard';
import novideo from '../../../Asserts/Images/novideos.png';
import Videos from './Videos';

export default function
Dashboard() {
    const navigate = useNavigate();
    const { user, token, activeButton, setActiveButton, clearVerification, loading, setSuccessAction, setSuccess } = useContext(UserContext);
    const [courses, setCourses] = useState([]);
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [popupIndex, setPopupIndex] = useState(null);
    const [deletepopup, setDeletepopup] = useState(false);
    const [deletedId, setDeletedId] = useState(null);
    const [whatsAppDetails, setWhatsAppDetails] = useState(null);
    const [subcategories, setSubCategories] = useState([]);
    const [dailyActivities, setDailyActivities] = useState([]);

    const [books ,setBooks] =useState([]);
    const [videoLoading,setVideoLoading] = useState(false);

    const [whatsappIcon, setWhatsappIcon] = useState();




    const handleButtonClick = (section) => {
        setActiveButton(section);

    };



    const searchDropdown = () => {
        setIsContentVisible(!isContentVisible);
    };

    const gotovideos = () => {
        navigate("/videos");
    };

    const gotowhatsapp = () => {

        const number = whatsAppDetails.whatsAppNumber;

        const encodedNumber = encodeURIComponent(number);
        const url = `whatsapp://send?phone=${encodedNumber}`;
        window.location.href = url;
    };

    const showpopup = (e, index) => {
        e.stopPropagation();
        setPopupIndex(popupIndex === index ? null : index);
    };

    const showdelete = async (e, id) => {
        e.stopPropagation();
        setDeletepopup(!deletepopup);
        setDeletedId(id);
    };



    const navigatesuccess = (e) => {
        e.stopPropagation();
        navigate("/success");
        setSuccessAction("/");
        setSuccess("success message");
    };

    const getWhatsAppDetails = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_SERVER_URL}/whatsapp/getDetails`, {},{
                headers: { "authorization": `Bearer ${token}` }
            });
            if (response.data) {
                setWhatsAppDetails(response.data.response[0]);
            }
        } catch (err) {
            console.error('Error fetching WhatsApp details:', err);
        }
    };
  

    useEffect(() => {
        clearVerification();
        handleButtonClick("الفيديوهات");
        getWhatsAppDetails();

    }, []);







    const gotouploadvideo = (e, id) => {
        e.stopPropagation();
        navigate(`/uploadvideo/${id}`);
    };

    return (
        <>

        <div className='maindashboarddiv' style={{ background: "#FAFAFA" }}>
            {whatsAppDetails?.feature_status && whatsAppDetails?.feature_status === true && (
                <div className="whatappicon" onClick={gotowhatsapp}>
                    <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
                </div>
            )}
            <Navbar isContentVisible={isContentVisible} searchDropdown={searchDropdown} />

            <div className="bannerdiv">
                <Slider />
            </div>

            <div className='iconmaindiv'>
                <button className={activeButton === "الفيديوهات" ? 'active' : ''} onClick={() => handleButtonClick("الفيديوهات")}>
                    الفيديوهات
                </button>
                <button className={activeButton === "الكتب" ? 'active' : ''} onClick={() => setActiveButton("الكتب")}>
                    الكتب
                </button>
            </div>




{activeButton === 'الفيديوهات' && (
        <Videos
          activeButton={activeButton}
           // Pass videos data as props
        />
      )}

      {/* Conditionally render Book component */}
      {activeButton === 'الكتب' && (
        <Book
          // Pass books data as props
          section={activeButton}
        />
      )}
            <div className="dashboardfooter">
                <Footer />
            </div>
        </div>

        </>
    );
}





