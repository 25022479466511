import React, { useState } from 'react';
import './Addsubcatageries.css'

import Navbar from '../../User/Navbar/Navbar'
import Footer from '../../User/Footer/Footer'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from "../../../context/UserContext";
import { Icon } from '@iconify/react/dist/iconify.js';
// import handleSessionError from '../../User/Managesession';
// import { useSessionErrorHandler } from '../../User/useSessionErrorHandler';

import { useSessionErrorHandler } from '../../User/Managesession';

export default function Adminaddsubcategory() {

    const handleSessionError = useSessionErrorHandler();

    const { setSuccess, setError, setSuccessAction,token } = useContext(UserContext);
    const navigate = useNavigate();
    const { section } = useParams();
    const [isOn, setIsOn] = useState(false);
    const [sectionType, setSectionType] = useState('');
    const [sectionTitle, setSectionTitle] = useState('');
    const [formsubmit, setFormsubmit] = useState(false);
    const handleAddSubsection =async () => {
        setFormsubmit(true);
        console.log('add subsection');
        const data = {
            title: sectionTitle,
        };
        const endpoint = `${process.env.REACT_APP_SERVER_URL}/section/updatesubsection/${sectionType}`;
await  axios.patch(endpoint,  data , {headers : {authorization : `Bearer ${token}`}})
            .then(response => {
                console.log(response)
                setSuccessAction(`/catageries`);
                setSuccess("تم إضافة القسم بنجاح")
                navigate("/success");
                console.log('Subsection added:', response.data);
                return
            })
            .catch(error => {
                setError("حدث خطأ")
                handleSessionError(error);

                console.error('There was an error adding the subsection:', error);
                return
            });
    };

    const toggleSwitch = () => {
        setIsOn(!isOn);
    };

const goback = ()=>{
    navigate(-1);
}
    
    return (
        <div>
            <Navbar />

            <div className="addasectionmaindiv">

<div className="topnavbardivmain">
<div onClick={goback}><Icon icon="weui:back-filled" width="1.2em" height="1.2em" /></div>
<div>إضافة قسم فرعي</div>
</div>

                {/* <h1> إضافة قسم فرعي</h1> */}

                <div className="maindivflexofaddasection">
                    <div className='departmentnamedivinput'>
                        <p> اسم القسم الفرعي</p>
                        <input
                            type="text"
                            value={sectionTitle}
                            onChange={(e) => setSectionTitle(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleAddSubsection();
                                }
                              }}
                            className={formsubmit && !sectionTitle ? 'input-error' : ''}
                        />
                    </div>

                    <div className='addasectionoptiondiv'>
                        <p>نوع القسم</p>
                        <select
                        className={formsubmit && !sectionType ? 'input-error' : ''}
                            value={sectionType}
                            onChange={(e) => setSectionType(e.target.value)}
                        >
                            <option value="">اختر نوع القسم</option>
                            <option value="الفيديوهات">فيديوهات</option>
                            <option value="الكتب">الكتب</option>
                        </select>
                    </div>
                </div>


                <div className='addasectionbuttondiv' onClick={handleAddSubsection}>
                    <button> إضافة القسم الفرعي</button>
                </div>
            </div>

            <Footer />
        </div>
    );
}