import React, { useEffect, useState, useContext } from 'react';
import './Footer.css';

import logo from "../../../Asserts/Images/logoa.png";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import { Icon } from '@iconify/react/dist/iconify.js';

export default function Footer() {
    const navigate = useNavigate();
    const { setSelectedCategory, selectedCategory } = useContext(UserContext);
    // console.log("selected category", selectedCategory);
    const user = JSON.parse(localStorage.getItem('user')); // Correctly parse user from local storage
    const [subcategories, setSubcategories] = useState([]);

    const getSubcategories = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/sections/getsubsections/الدورات`);
            console.log('inside footer', response.data.data);
            setSubcategories(response.data.data.subSections);
        } catch (err) {
            console.log(err);
        }
    }

    const handlefooterclick = (title) => {
        console.log('clicked', title);
        if (user) {
            setSelectedCategory(title);
            navigate('/');
        } else {
            navigate('/');
        }
    }

    // useEffect(() => {
    //     console.log("inside useEffect footer");
    //     // getSubcategories();
    // }, []);

    const gothecallus = () => {
        navigate("/callus");
    }

    const gotorules = ()=>{
        navigate("/usingpolicy")
    }

    const abouttheacademy = () => {
        navigate("/owner");
    }

    const gotohome = () => {
        navigate('/');
    }

const gotofacbook = ()=>{
    window.open("http://facebook.com/dmazensakka")
}

const gotoyoutube = ()=>{
    window.open("https://www.youtube.com/channel/UCiEV3Spp6YtZQX6JdW5YMeQ")
}

const gototwitter = ()=>{
    window.open("https://twitter.com/sakkamazen")
}

const linkdin = ()=>{
    window.open("https://www.linkedin.com/in/mazen-el-sakka-bb1b6551/")
}

const gotoinstagram = ()=>{
    window.open("https://www.instagram.com/dmazensakka/?hl=en")
}

const gototiktok = ()=>{
    window.open("https://www.tiktok.com/@mazen_elsakka_academy")
}

const gotosnapchat = ()=>{
    window.open("https://snapchat.com/t/rg4aVg5x")
}

    return (
        <div>
            <div className="footermaindiv">
                <div className="mainflexdivoffooter">
                    <div className='imgandlogodiv' onClick={gotohome}>
                        <img src={logo} alt="" />
                        <h2>د.مازن السقا</h2>
                    </div>

                    <div className='maintwodivparent'>
                        <div className='persondiv'>
                            {/* <h4>روابط سريعة</h4> */}
                            {/* <div onClick={abouttheacademy} className='personlogoandimgdiv'>
                                <p>عن الأكاديمية <Icon icon="tabler:user-filled" width="1.2em" height="1.2em" /></p>

                            </div> */}
                            <div onClick={gothecallus} className='personlogoandimgdiv'>
                                <p>اتصل بنا</p>
                                <Icon icon="jam:messages-f" style={{color: 'black'}} width="1.2em" height="1.2em" />
                            </div>
                            <div className='personlogoandimgdiv' onClick={gotorules}>
                                <p>
                                    سياسة الاستخدام
                                    
                                    
                                </p>
                                <Icon icon="bi:file-text"  style={{color: 'black'}} width="1.2em" height="1.2em" />
                            </div>

                        </div>

                        <div className='lastlinks'>
                            {/* <h4> الدورات</h4> */}
                            <ul className='ufooterlinks'>
                                {subcategories.map((subcategory) => (
                                    <div className='ulandpicmaindiv' key={subcategory._id} onClick={() => handlefooterclick("الدورات")}>
                                        <p>{subcategory.title}</p>
                                        {/* <img src={dotpic} alt="" /> */}

                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className='footerlastp'>
                    <p> جميع الحقوق محفوظة لشركة بكم نرتقي المحدودة 2024</p>
                </div>

                <div className='iconsdiv'>
                    <FontAwesomeIcon onClick={linkdin} className='footer_icons' icon={faLinkedinIn} />
                    <FontAwesomeIcon onClick={gototwitter} className='footer_icons' icon={faTwitter} />
                    <FontAwesomeIcon onClick={gotoinstagram} className='footer_icons' icon={faInstagram} />
                    <FontAwesomeIcon onClick={gotofacbook} className='footer_icons' icon={faFacebook} />
                    <FontAwesomeIcon onClick={gotoyoutube} className='footer_icons' icon={faYoutube} />

                    <Icon onClick={gototiktok} className='footer_icons iconificons' icon="ic:baseline-tiktok" width="1.2em" height="1.2em" />

                    <Icon onClick={gotosnapchat} className='footer_icons iconificons' icon="mingcute:snapchat-fill" width="1.2em" height="1.2em" />
                </div>
            </div>
        </div>
    );
}
