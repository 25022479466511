import React, { useState } from 'react';
import { Icon } from '@iconify/react/dist/iconify.js';
import './Buybook.css';

function Share() {
  const [url, setUrl] = useState('');

  

  const handleCopy = () => {
    navigator.clipboard.writeText(url).then(() => {
      alert('URL copied to clipboard');
    });
  };

  const handleShare = (platform) => {
    let shareUrl = '';

    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent('Check out this link!')}`;
        break;
      case 'whatsapp':
        shareUrl = `https://wa.me/?text=${encodeURIComponent('Check out this link: ' + url)}`;
        break;
      case 'messenger':
        shareUrl = `https://www.facebook.com/dialog/send?link=${encodeURIComponent(url)}&app_id=YOUR_APP_ID&redirect_uri=${encodeURIComponent(url)}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=Check%20this%20out!`;
        break;
      case 'telegram':
        shareUrl = `https://t.me/share/url?url=${encodeURIComponent(url)}&text=Check%20this%20out!`;
        break;
      case 'instagram':
      case 'tiktok':
      case 'youtube':
      case 'snapchat':
        alert(`${platform.charAt(0).toUpperCase() + platform.slice(1)} does not support direct link sharing via URL on web.`);
        return;
      default:
        return;
    }

    window.open(shareUrl, '_blank');
  };

  return (
    <>
      <div className="sharemaindiv">
        <div className="sharecontainer" onClick={(e) => e.stopPropagation()}>
          <div className="shareinputbutton">
            <input
              type="url"
              placeholder="https://www........."
              value={url}
            />
            <button onClick={handleCopy}>نسخ الرابط</button>
          </div>

          <div className="socialmediaicons">
            <div
              className="sameall"
              style={{ background: '#1877F2', color: 'white' }}
              onClick={() => handleShare('facebook')}
            >
              <Icon icon="ri:facebook-fill" width="1.2em" height="1.2em" />
            </div>

            <div
              className="sameall"
              style={{ background: '#00E510', color: 'white' }}
              onClick={() => handleShare('whatsapp')}
            >
              <Icon icon="bi:whatsapp" width="1.2em" height="1.2em" />
            </div>

            <div
              className="sameall"
              style={{
                background:
                  'linear-gradient(45deg, #FAAD4F 14.61%, #DD2A7B 39.38%, #9537B0 58.49%, #515BD4 85.39%)',
                color: 'white',
              }}
              onClick={() => handleShare('instagram')}
            >
              <Icon icon="hugeicons:instagram" width="1.2em" height="1.2em" />
            </div>

            <div className="sameall" style={{background:"#FF0209", color:"white"}}
              onClick={() => handleShare('youtube')}
            >
              <Icon icon="mdi:youtube" width="1.2em" height="1.2em" />
            </div>

            <div className="sameall" style={{background:"#000000", color:"white"}}
              onClick={() => handleShare('tiktok')}
            >
              <Icon icon="ic:outline-tiktok" width="1.2em" height="1.2em" />
            </div>

            <div
              className="sameall"
              style={{
                background:
                  'linear-gradient(45deg, #0498FA 14.61%, #5C5EFE 38.67%, #F44E90 66.28%, #FA8679 85.39%)',
                color: 'white',
              }}
              onClick={() => handleShare('messenger')}
            >
              <Icon icon="fe:messanger" width="1.2em" height="1.2em" />
            </div>

            <div className="sameall" style={{background:"#FFFA00", color:"black"}}
              onClick={() => handleShare('snapchat')}
            >
              <Icon icon="ic:outline-snapchat" width="1.2em" height="1.2em" />
            </div>

            <div
              className="sameall"
              style={{ background: '#000000', color: 'white' }}
              onClick={() => handleShare('twitter')}
            >
              <Icon icon="prime:twitter" width="1.2em" height="1.2em" />
            </div>

            <div
              className="sameall"
              style={{ background: '#00B0F2', color: 'white' }}
              onClick={() => handleShare('telegram')}
            >
              <Icon icon="icon-park-outline:telegram" width="1.2em" height="1.2em" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Share;
