import React from 'react'
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './Networkerror.css'
import networkerrorimg from '../../../Asserts/Images/networkerror.png'

function Networkerror() {
  return (
    <>
    <div className="netowkerrormain">
<div className="natworkerrornav">
    <Navbar/>
</div>

<div className="networkerrorcontainer">
<img src={networkerrorimg} alt="" />
خطأ بالاتصال
</div>

<div className="networkerrorfooter">
    <Footer/>
</div>


    </div>
    
    </>
  )
}

export default Networkerror;