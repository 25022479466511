import React ,{useContext, useEffect, useState} from 'react'
import Navbar from '../../User/Navbar/Navbar';
import Footer from '../../User/Footer/Footer';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../context/UserContext';
import './Textchecknoti.css';
import Meetingnotify from '../Meetingnotification/Meetingnotify';
import Loading from '../../User/Loading/Loading';
import norequest from  '../../../Asserts/Images/norequest.png'
import { useSessionErrorHandler } from '../../User/Managesession';
import BranchLoading from '../../User/branchLoading/BranchLoading';



function TextChecknoti() {

  const handleSessionError = useSessionErrorHandler();

  const [requests,setRequests] = useState([]);
  const [activeButton,setActiveButton] = useState('مفتوحة')
  const {activeButtontest,setActiveButtontest,loading,setLoading,activeTab,setActiveTab,token} = useContext(UserContext)
  const navigate = useNavigate()
  const [showConfirm, setShowConfirm] = useState(false);


    const buttonStyles = {
      'مفتوحة': { backgroundColor: '#2F7BD5', color: 'white' },
      'مغلقة': { backgroundColor: '#993333', color: 'white' },
      'قيد المعالجة': { backgroundColor: '#F1DA37', color: 'white' },
    };


    const handleAllUserReq = async(button) => {
      try{
        setLoading(true)
      setActiveButton(button);
      await axios.get(`${process.env.REACT_APP_SERVER_URL}/patientrequests/getpatientRequestsbystatus/${button}`,{headers : {authorization : `Bearer ${token}`}}).then((req)=>{
        console.log(req.data)
        if(req.data.status ==="success"){
          setRequests(req.data.requests)
          setLoading(false)
          return
        }
      }).catch((err)=>{
        console.log("upper",err)
        handleSessionError(err);
        if(err.response.status ===404){

          setRequests([])
          setLoading(false)
        return
        }

      })
      }catch(err){
handleSessionError(err);
        console.log("catch err",err)
        return
      }

    };
    useEffect(()=>{
      handleAllUserReq(activeButton)
    },[])

  const getColor = () => {
    switch (activeButton) {
      case 'مفتوحة':
        return '#2F7BD5';
      case 'مغلقة':
        return '#993333';
      case 'قيد المعالجة':
        return '#F1DA37';
      default:
        return '#2F7BD5'; // Default color for unexpected values
    }
  };

  const showConfirmation = (e) => {
    e.stopPropagation();
    setShowConfirm(!showConfirm);
  };

  const handleCloseCase = () => {
    alert('Case handling is executed.');
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };


const gotomeeting = ()=>{
  setActiveTab('تحليلات')
  navigate('/meetingnotify');
}

  return (
    <>
      <div className="textchecknotimain">
        {showConfirm && (
          <div className="conformationboxmain">
            <div className="showconfirmcontainer">
              <button onClick={handleCloseCase}>Confirm</button>
              <button onClick={(e) => showConfirmation(e)}>Cancel</button>
            </div>
          </div>
        )}

        <div className="whatappicon">
          <Icon icon="ic:round-whatsapp" width="1.2em" height="1.2em" />
        </div>
        <div className="textchecknotifinav">
          <Navbar />
        </div>
        <div className="texchecknotificontainer">
          <div className="navigationbuttons">
            <div
              className={` ${activeTab === 'تحليلات' ? 'activebuttondiv' : 'unactiveclass'}`}
              onClick={() => gotomeeting()}
            >
              اللقاءات

            </div>
            <div
              className={` ${activeTab === 'لقاءات' ? 'activebuttondiv' : 'unactiveclass'}`}
              onClick={() => handleTabClick('لقاءات')}
            >
              التحليلات

            </div>
          </div>

          {activeTab === 'لقاءات' && <>

            <div className="navigationroutesmain">
      {['مفتوحة', 'مغلقة', 'قيد المعالجة'].map((button) => (
        <div
          key={button}
          style={activeButton === button ? buttonStyles[button] : {}}
          onClick={() => handleAllUserReq(button)}
        >
          {button}
        </div>
      ))}
    </div>



<div className="cardsdivmain">
{
  loading ? <BranchLoading/> : <>{
requests && requests.length <= 0 ? <>

<div className="norequestmaindiv">
<img src={norequest} alt="" />
لم تقم بطلب قراءة أي تحليل
</div>

</> : requests.map((request) => {
  // Check if created_at is a valid date
  const requestDate = new Date(request.created_at);

  // Format the time as hh:mm am/pm
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
  const formattedTime = requestDate.toLocaleTimeString('en-US', timeOptions);

  // Format the date as dd/mm/yyyy
  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const formattedDate = requestDate.toLocaleDateString('en-GB', dateOptions);
  const dayOptions = { weekday: 'long' };
  const formattedDay = requestDate.toLocaleDateString('en-US', dayOptions);
  return (
    <div className="notificationcardsmain" onClick={() => navigate(`/collobies/${request._id}`)}>
      <div className="notificationcardleft" style={{ backgroundColor: getColor() }}>
        {request.request_status}
      </div>

      <div className="notificatoncardright">
        <div className="noticardtopdiv">
          <div className="notiardtopleft">
            {request.patient_phone && request.patient_phone ? request.patient_phone : ''}
          </div>

          <div className="noticardtopright">
            <div>{request.patient_name||request.requested_by.first_name+" "+request.requested_by.last_name}</div>
            <div>{request.request_title}</div>
            <div className='givepurplecolor'> {formattedTime} {formattedDate  } :{formattedDay}</div>
          </div>

        </div>

        <div className="noticardbottomdiv">
          {request.request_description}
        </div>
      </div>
    </div>
  );
})
}</>
}

</div>
</>}

</div>


{/* {activeTab === 'تحليلات' &&
          <>

         <Meetingnotify/>

          </>} */}

<div className="textchecknotififooter">
    <Footer/>
</div>

    </div>
    </>
  );
}

export default TextChecknoti;


