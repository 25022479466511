import React from 'react'
import './SimpleNavbar.css'

import logo from "../../../Asserts/Images/logoa.png";
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';

export default function SimpleNavbar() {
const {user}  = useContext(UserContext)
const navigate = useNavigate();

const gotohomepage = ()=>{
  if(user){
    navigate("/")
  }
  else{
    navigate('/')
  }
}

  return (
    <div className='newtopsimplenavbar'>

        <div className="simplenavbarmaindiv" >
            <img onClick={gotohomepage} src={logo} alt="logo" />
            <h2 onClick={gotohomepage}> د.مازن السقا</h2>
        </div>
    </div>
  )
}
