import React from 'react'
import { Icon } from '@iconify/react/dist/iconify.js'

function BranchLoading() {
  return (
    <div className='LoadingInFetchingBooksContainer'>
      <div className='LoadingInFetchingBooks'>
   <Icon icon="eos-icons:bubble-loading" width="1.2em" height="1.2em" />
      </div>
    </div>
  )
}

export default BranchLoading
