
import React, { useState, useEffect, useContext } from 'react';
import './Uploodbook.css';
import Footer from '../../User/Footer/Footer';
import Navbar from '../../User/Navbar/Navbar';
import { Icon } from '@iconify/react/dist/iconify.js';
import { useNavigate, useParams } from 'react-router-dom';
import book from '../../../Asserts/Images/book.svg';
import { UserContext } from '../../../context/UserContext';
import axios from 'axios';
import { useSessionErrorHandler } from '../../User/Managesession';

function Uplaodbook() {

  const handleSessionError = useSessionErrorHandler();

  const { setSuccessAction, setSuccess,token ,setLoading,loading} = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams();

  const [bookFile, setBookFile] = useState(null);
  const [bookFileName, setBookFileName] = useState('');
  const [bookImage, setBookImage] = useState(null);
  const [bookImageUrl, setBookImageUrl] = useState(book);
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [authorInfo, setAuthorInfo] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);


  // Fetch the book data from the backend when the component mounts

  useEffect(() => {
    setLoading(true);
    axios.get(`${process.env.REACT_APP_SERVER_URL}/book/view/${id}`,{headers : {authorization : `Bearer ${token}`}})
      .then(response => {
        console.log('Book data:', response.data);
        const data = response.data.book;
        setTitle(data.bookTitle);
        setPrice(data.bookPrice);
        setAuthor(data.author);
        setBookImageUrl(data.bookCoverPhoto)
        setDescription(data.book_description);
        setAuthorInfo(data.authorInfo);
        if (data.imageUrl) {
          setBookImageUrl(data.bookCoverPhoto); // Store existing image URL
        }
        if (data.fileName) {
          setBookFileName(data.bookPDF); // Store existing PDF name
        }
      })
      .catch(error => {console.error('Error fetching book data:', error)
        handleSessionError(error);
      });
  }, [id]);

  const handleBookFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBookFile(file);
      setBookFileName(file.name);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setBookImage(file);
      setBookImageUrl(URL.createObjectURL(file)); // Display the new image preview
    }
  };

  const goback = () => {
    navigate(-1);
  };

  const handleSubmit = async () => {
    console.log('Submitting form...');
    setLoading(true);
    setFormSubmitted(true);
    if (title && price && author && description && authorInfo ) {
      const formData = new FormData();
      formData.append('bookTitle', title);
      formData.append('bookPrice', price);
      formData.append('author', author);
      formData.append('book_description', description);
      formData.append('authorInfo', authorInfo);
      if (bookFile) formData.append('bookPDF', bookFile);
      if (bookImage) formData.append('bookCoverPhoto', bookImage);

      await axios.patch(`${process.env.REACT_APP_SERVER_URL}/book/update/${id}`, formData,{headers : {authorization : `Bearer ${token}`}})
        .then(response => {
          console.log('Book data updated:', response.data);
          setSuccess("تم تعديل البيانات بنجاح");
          setSuccessAction(`/buybook/${response.data.response._id}`);
          navigate('/success');
        })
        .catch(error => {console.error('Error updating book data:', error)

          handleSessionError(error);
        });
    }
    else{
      alert('يرجى ملء جميع الحقول')
    }
  };

  return (
    <>
      <div className="uploadmaindiv">
        <div className="uplaodbooknavbar">
          <Navbar />
        </div>

        <div className="uploadbookcontainer">
          <div className="topupernavbar">
            <div><Icon onClick={goback} icon="ep:arrow-left-bold" width="1.2em" height="1.2em" /></div>
            <div>تعديل بيانات الكتاب</div>
          </div>

          <div className="bookcontainerdiv">
            {bookImageUrl ? (
              <img src={bookImageUrl} alt="Book Cover" className="uploadedBookImage" />
            ) : (
              <img src={book} alt="Default Book" />
            )}
          </div>

          <button className="uploadButton">
            <label htmlFor="bookImageUpload" className="customUploadButton">
              ارفع صورة الكتاب
            </label>
            <input
              type="file"
              id="bookImageUpload"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
            />
          </button>

          <div className="inputmaindivtitle">
            <label htmlFor="bookTitle">عنوان الكتاب</label>
            <input
              type="text"
              id="bookTitle"
              placeholder='جرثومة المعدة'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={formSubmitted && !title ? 'input-error' : ''}
            />
          </div>

          <div className="twoinputsmaindiv">
            <div className="leftshortdiv">
              <label htmlFor="bookPrice">السعر</label>
              <input
                type="number"
                id="bookPrice"
                placeholder='0'
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                className={formSubmitted && !price ? 'input-error' : ''}
              />
            </div>

            <div className="rightlonginput">
              <label htmlFor="bookAuthor">اسم الكاتب</label>
              <input
                type="text"
                id="bookAuthor"
                placeholder='دزمازن السقا'
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                className={formSubmitted && !author ? 'input-error' : ''}
              />
            </div>
          </div>

          <div className="descraptiondivmain">
            <label htmlFor="bookDescription">الوصف</label>
            <textarea
              id="bookDescription"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className={formSubmitted && !description ? 'input-error' : ''}
            ></textarea>
          </div>

          <div className="descraptiondivmain">
            <label htmlFor="authorInfo">معلومات عن المؤلف</label>
            <textarea
              id="authorInfo"
              value={authorInfo}
              onChange={(e) => setAuthorInfo(e.target.value)}
              className={formSubmitted && !authorInfo ? 'input-error' : ''}
            ></textarea>
          </div>

          <div className="booknamedivbookicon">
            {bookFileName ? bookFileName : "No Book Uploaded"}
            <Icon icon="material-symbols-light:book-4-rounded" width="1.2em" height="1.2em" />
          </div>

          <button className="uploadButton">
            <label htmlFor="bookUpload" className="customUploadButton">
              ارفع ملف الكتاب
            </label>
            <input
              type="file"
              id="bookUpload"
              accept=".pdf, .doc, .docx, .epub"
              onChange={handleBookFileChange}
              style={{ display: 'none' }}
            />
          </button>

          <button onClick={handleSubmit}>حفظ</button>
        </div>

        <div className="uplaodbookfooter">
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Uplaodbook;

