// context/UserContext.js
import React, { createContext, useState } from 'react';

const ManagersContext = createContext();

const ManagersProvider = ({ children }) => {

    const [managerSlide,setManagerSlide] = useState(1)
    const [managersData,setManagersData] = useState()


    const [allmanagerpopup,setAllmanagerpopup] = useState(true)
    const [activity,setActivity] = useState('videos')

  return (
    <ManagersContext.Provider value={{activity,setActivity,allmanagerpopup,setAllmanagerpopup,managerSlide,setManagerSlide,managersData,setManagersData}}>
      {children}
    </ManagersContext.Provider>
  );
};

export { ManagersContext, ManagersProvider };